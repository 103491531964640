import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Footer from "./footer";
function Return() {
    return (
        <>
            <Navbar />
            <Navbar2 />
            <div class="container my-lg-3 my-2 mb-5 pb-3 pt-3">
                <h4><b>1.	Exchange Policy</b></h4>
                <p>
                    •	If a product is unused and in its original, saleable condition, the customer may return it in the country where purchased within 7 days of purchase, provided the customer submits the original invoice.<br />
                    •	If the product was purchased as part of a bundle/offer, all items included as part of such bundle/offer must be returned together in same condition mentioned in the above point.
                </p>
                <b>Customer Exchange Process:</b><br />
                <p>•	Purchases from a retail store must be exchanged at a retail shop in the same country. Don’t forget your original invoice.
                    <br />•	Purchases from the online store must be exchanged by calling the Iqbal & Tareq Mobile Phones LLC Contact Centre at +971 4 272 6417 and requesting a pick up.
                </p>
                <h4><b>2.	Warranty Policy</b></h4>
                <b>For products with manufacturer defects:</b><br />
                <p>•	For all accessories (excluding certain Switch products), the relevant manufacturers’ warranty will apply. Iqbal & Tareq Mobile Phones LLC will assist the customer to the extent possible. Original invoice is required.
                    <br />•	For mobile devices (including tablets, phones and others), the Iqbal & Tareq Mobile Phones LLC 1 Year Warranty will apply for 12 months (for mobile devices purchased in the UAE) from date of the original invoice.
                    <br />•	The following specific conditions apply to the Iqbal & Tareq Mobile Phones LLC 1 Year Warranty for mobile devices:<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i.	Damage Protection and Extended Warranty are subject to applicable terms and conditions, as communicated at the time of purchase.
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii.	Service, repair or alterations by any personnel other than Iqbal & Tareq Mobile Phones LLC’s authorized service centers will invalidate any warranty.
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii.	Any damage due to accident, transportation, misuse, negligence, natural disaster, humidity, rust, gradual deterioration due to wear and tear, cosmetic modification, drop damage/liquid damage, use with voltages other than designated, or operation in a manner not in accordance with the manufacturer’s instruction manual is expressly excluded from warranty coverage.
                    <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv.	Iqbal & Tareq Mobile Phones LLC is not responsible in any manner for personal data, third party or try & buy applications stored or installed on the mobile device.
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v.	The repaired mobile device will be warranted for the balance of the original warranty period.
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi.	Iqbal & Tareq Mobile Phones LLC is authorized to replace the main boards only as per the manufacturer’s troubleshooting and warranty replacement guidelines.
                    <br /> •	If the mobile device is beyond economical repair, Iqbal & Tareq Mobile Phones LLC may replace the mobile device, at its absolute discretion.
                    <br />•	Customer utilization of the Iqbal & Tareq Mobile Phones LLC 1 Year Warranty:
                    <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i.	All customers with may go to Iqbal & Tareq Mobile Phones LLC Service Centre (Iqbal & Tareq Mobile Phones LLC care) or the location where they purchased the mobile device in the UAE with their defective mobile device.
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii.	Purchases from the online store may also be dropped-off to the Iqbal & Tareq Mobile Phones LLC retail store.<br /><br />
                    For products shipped internationally, please note that any manufacturer warranty may not be valid; manufacturer service options may not be available; product manuals, instructions and safety warnings may not be in destination country languages; the products (and accompanying materials) may not be designed in accordance with destination country standards, specifications, and labeling requirements; and the products may not conform to destination country voltage and other electrical standards (requiring use of an adapter or converter if appropriate). You are responsible for assuring that the product can be lawfully imported to the destination country. When ordering from the Iqbal & Tareq Mobile Phones LLC site you are the importer of record and must comply with all laws and regulations of the destination country.
                </p>

                <h4><b>3.	Privacy Policy (Data Protection)</b></h4>
                <p>Your privacy is important to us. Iqbal & Tareq Mobile Phones LLC site know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly and within the framework of good practice and compliance with applicable laws. By visiting the Iqbal & Tareq Mobile Phones LLC site, you are accepting the practices described in this privacy statement.<br/>
                •	Information You Give Us: We receive and store any information you enter on the Iqbal & Tareq Mobile Phones LLC site or give us in any other way. You can choose not to provide certain information, but then you might not be able to take advantage of many of our features. We use the information that you provide for such purposes as responding to your requests, customizing future shopping for you, improving our stores, and communicating with you.
                <br/>•	Automatic Information: We receive and store certain types of information whenever you interact with us. For example, like many Web sites, we use "cookies," and we obtain certain types of information when your Web browser accesses the Iqbal & Tareq Mobile Phones LLC site.
                <br/>•	Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device and to provide certain features.
                <br/>•	Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as needed for the legitimate purposes of our business.
                <br/>•	Third-Party Service Providers: We employ other companies and individuals to perform functions on our behalf. Examples include fulfilling orders, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments, and providing customer service. They have access to personal information needed to perform their functions, but may not use it for other purposes.
                <br/><b>Promotional Offers:</b><br/> Sometimes we send offers to selected groups of customers.
                <br/>•	We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Terms and other agreements; or protect the rights, property, or safety of the Iqbal & Tareq Mobile Phones LLC site, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
                <br/>•	We work to protect the security of your information during transmission with appropriate and adequate procedures to prevent unauthorized access to, and the misuse of, personal data that we process.
                <br/>•	We use third party payment system providers who meet applicable international standards.
                <br/>•	It is important for you to protect against unauthorized access to your password and to your device. Be sure to sign off when finished using a shared device.
                We might amend this Privacy Statement from time to time. Visit this page regularly to stay up to date.
                </p>
            </div>
            <Footer />
        </>
    )
}
export default Return;
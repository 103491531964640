import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import increment from "../actions";
import Loader from "./common/loader";
import Logo from "../itphone_logo.png";

function Navbar() {
  const [log, setLog] = useState(false);
  const [active, setActive] = useState("");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);

  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
        setLoad(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandList(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      const data = new FormData();
      data.append("token", localStorage.getItem("token"));
      fetch("https://server.itphonestore.com/itphone/auth", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setLog(res.message);

          var name = res.result[0].fname.substr(
            0,
            res.result[0].fname.indexOf(" ")
          );

          setActive(res.result[0].fname);
          console.log(active);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  document.body.addEventListener("click", function (event) {
    setShow(false);
  });

  return (
    <>
      <Loader load={load} />
      <nav class="navbar navbar-expand-lg sticky-top shadow-sm moboff pb-0">
        <div class="row container-fluid px-lg-5 px-2 justify-contain-between">
          <div class="col-lg-2 navbar-brand">
            <Link to={"/"}>
              <img src={Logo} class="navbar-logo-1" alt="" srcset="" />
            </Link>
          </div>
          <div class="col-lg-10 row">
            <div class="col-lg-8 my-auto  ">
              <div class=" bg-white rounded">
                <div class="input-group " style={{ position: "relative" }}>
                  <input
                    class="form-control form-control-search py-2 home-search"
                    type="search"
                    placeholder="Search here . . . "
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setShow(true);
                    }}
                  />
                  <span class="input-group-prepend bcolor rius">
                    <div class="input-group-text home-search">
                      <i class="fa fa-search" style={{ color: "#fff" }}></i>
                    </div>
                  </span>

                  {show ? (
                    <div class="sbox">
                      <div class="list-group">
                        {product
                          .filter((item) => {
                            if (
                              item.pname
                                ?.toLocaleLowerCase()
                                .includes(search?.toLocaleLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((item) => (
                            <Link
                              onClick={() => {
                                setShow(!show);
                              }}
                              to={"/product/" + item.id}
                              class="list-group-item list-group-item-action"
                            >
                              {item.pname}
                            </Link>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div class="col-lg-2 text-end  ">
              <Link
                to={"/cart"}
                type="button"
                class="btn btn-white navusername position-relative fa-solid fa-shopping-cart"
              >
                <span
                  class="position-absolute top-100 start-100 translate-middle badge badge-cart px-1 py-2"
                  style={{ backgroundColor: "#551427" }}
                >
                  <p class="">{counter}</p>
                  <span class="visually-hidden">unread messages</span>
                </span>
              </Link>
            </div>
            <div class="col-lg-2 text-end">
              {log ? (
                <Link to={"/account"}>
                  <button class="btn userbtn user-btn">
                    <div class="d-flex justify-content-between ">
                      <p class="navusername m-0 mx-3">{active && active}</p>
                      {/* <img
                        src={require("../image/icons/user.png")}
                        class="user-btn-logo"
                        alt=""
                        srcset=""
                      /> */}
                      <i class="fa-regular fa-user user-btn-logo navusername"></i>
                    </div>
                  </button>
                </Link>
              ) : (
                <Link to={"/login"}>
                  <button class="btn user-btn userbtn">
                    <div class="d-flex justify-content-between ">
                      <p class="navusername m-0 mx-3">Log In</p>
                      {/* <img
                        src={require("../image/icons/user.png")}
                        class="user-btn-logo"
                        alt=""
                        srcset=""
                      /> */}
                      <i class="fa-regular fa-user user-btn-logo"></i>
                    </div>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div class="row container-fluid px-lg-5 px-2 mx-0 mt-2 justify-contain-between">
          <div class="col-lg-6 px-0 d-flex align-items-center">
            <div class="dropdown py-0">
              <button
                class="seealln px-4 border-0"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All Category{" "}
                <i class="fa-sharp fa-solid fa-chevron-down mx-2"></i>
              </button>
              <div
                class="dropdown-menu allcate rounded-0 border-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <div class="row mx-3">
                  {category.map((cate) => (
                    <div class="col-lg-4">
                      <p>
                        <Link
                          className="navlinkc fw-bold"
                          to={"/category/" + cate.cname + "/all"}
                        >
                          {cate.cname}
                        </Link>
                      </p>

                      {brandList.map((item) => {
                        if (item.brandCate == cate.cname) {
                          return (
                            <>
                              <small>
                                <Link
                                  className="navlinkc"
                                  to={
                                    "/category/" +
                                    cate.cname +
                                    "/" +
                                    item.brandName
                                  }
                                >
                                  {item.brandName}
                                </Link>
                              </small>
                              <br></br>
                            </>
                          );
                        }
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="d-flex mx-4">
              {" "}
              <Link to={"/newrelease"} class="navmenu">
                <p class="mx-3 mb-0">
                  New Release<span style={{ color: "#551427" }}>*</span>
                </p>
              </Link>
            </div>
            <div class="d-flex mx-3">
              {" "}
              <Link to={"/wholesale"} class="navmenu">
                <p class="mx-3 mb-0">Wholesale</p>
              </Link>
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="text-end">
              <Link to={"/service-center"}>
                <small class="mx-2 navtexts">About IT Phone</small>
              </Link>
              <Link to={"/policy"}>
                <small class="mx-2 navtexts">Policy</small>
              </Link>
              <Link to={"/terms"}>
                <small class="mx-2 navtexts">Terms and Conditions</small>
              </Link>
              <Link to={"/contact"}>
                <small class="mx-2 navtexts">Contact Us</small>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PersonalInfo() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [allprod, setAllprod] = useState([]);
  const [coun, setCoun] = useState(0);
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      const data = new FormData();
      data.append("token", localStorage.getItem("token"));
      fetch("https://server.itphonestore.com/itphone/fetchinfo", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setFname(res.message[0].fname);
          setLname(res.message[0].lname);
          setEmail(res.message[0].email);
          setPhone(res.message[0].phone);
          setAddress(res.message[0].address);
        })
        .catch((err) => console.log(err));
    }
  }, [coun]);

  function UpdateProfile() {
    if (!edit) {
      const data = new FormData();
      data.append("fname", fname);
      data.append("token", localStorage.getItem("token"));

      fetch("https://server.itphonestore.com/itphone/updateProfile", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          toast.success("তথ্য পরিবর্তন করা হয়েছে", {
            icon: "✅",
          });
          setCoun(coun + 1);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <div class="conatiner my-4 py-3">
      <p class="fs-4 pb-3">Personal Info</p>
      <div class="row">
        <div class="col-lg-8 px-4">
          <form class="row g-3">
            <div class="col-lg-12">
              <label for="inputfullname" class="form-label text-muted pb-2">
                Name
              </label>
              <input
                type="text"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                class="form-control form-control-input"
                id="inputfullname"
                readOnly={edit}
              />
            </div>

            <div class="col-lg-12">
              <label for="inputEmail4" class="form-label text-muted pb-2">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="form-control form-control-input"
                id="inputEmail4"
                readOnly="false"
              />
            </div>
            <div class="col-lg-12">
              <label for="inputnumber" class="form-label text-muted pb-2">
                Mobile Number
              </label>
              <input
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                class="form-control form-control-input"
                id="inputnumber"
                readOnly="false"
              />
            </div>
          </form>
        </div>
        <div class="col-lg-8 my-4">
          <button
            onClick={() => {
              setEdit(!edit);
              UpdateProfile();
            }}
            type="submit"
            class="btn btn-edit-profile w-50 fs-6 "
          >
            Update Info
          </button>
        </div>

        <div class="col-lg-8">
          <button type="submit" class="btn btn-edit-pass w-50 fs-6 ">
            Password Change
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;

import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Navbar2 from "./navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Reg() {
  let history = useHistory();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [msg2, setMsg2] = useState("");

  function submitHandle() {
    if (fname == "") {
      toast.dark("Name field empty", {
        icon: "👤",
      });

      return false;
    }

    if (email == "") {
      toast.dark("E-mail field empty", {
        icon: "📧",
      });

      return false;
    }

    if (phone == "") {
      toast.dark("Mobile number empty", {
        icon: "📱",
      });

      return false;
    }

    if (password == "") {
      toast.dark("Password empty", {
        icon: "🔑",
      });

      return false;
    }

    if (msg != "") {
      toast.dark("This email alredy used", {
        icon: "⚠️",
      });

      return false;
    }

    if (msg2 != "") {
      toast.dark("This mobile number already used", {
        icon: "⚠️",
      });

      return false;
    }

    const data = new FormData();
    data.append("fname", fname);
    data.append("lname", lname);
    data.append("email", email);
    data.append("phone", phone);
    data.append("address", address);
    data.append("password", password);

    fetch("https://server.itphonestore.com/itphone/userpost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("token", res.token);
        history.push("/account");
      })
      .catch((err) => console.log(err));
  }

  function mcheck(x) {
    const data = new FormData();
    data.append("email", x);

    fetch("https://server.itphonestore.com/itphone/mcheck", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => setMsg(res.message))
      .catch((err) => console.log(err));
  }

  function pcheck(x) {
    const data = new FormData();
    data.append("phone", x);

    fetch("https://server.itphonestore.com/itphone/pcheck", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => setMsg2(res.message))
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div class="container my-lg-5 my-3 log-w">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-12">
            <div class="card pt-3 rounded-0">
              <div class="card-body px-5">
                <p class="fs-5 fw-bold mb-4">Create Account Here!</p>

                <div class="col-lg-12">
                  <label>
                    <small style={{ color: "#262626" }}>Full Name*</small>
                  </label>
                  <input
                    onChange={(e) => setFname(e.target.value)}
                    type="text"
                    class="form-control log-f-border"
                    placeholder="Your Name"
                    aria-label="Your first name"
                  />
                </div>
                <div class="col-lg-12 my-3">
                  <small>{msg}</small>
                  <label>
                    <small style={{ color: "#262626" }}>E-mail*</small>
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                      mcheck(e.target.value);
                    }}
                    type="email"
                    class="form-control log-f-border"
                    placeholder="Your Email"
                    aria-label="Your email"
                  />
                </div>
                <div class="col-lg-12 my-3">
                  <small>{msg2}</small>
                  <label>
                    <small style={{ color: "#262626" }}>Mobile Number</small>
                  </label>
                  <input
                    onChange={(e) => {
                      setPhone(e.target.value);
                      pcheck(e.target.value);
                    }}
                    type="number"
                    class="form-control log-f-border"
                    placeholder="XXX XXX XXXX"
                    aria-label="Mobile number"
                  />
                </div>

                <div class="col-lg-12 my-3">
                  <label>
                    <small style={{ color: "#262626" }}>Password*</small>
                  </label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    class="form-control log-f-border"
                    placeholder="********"
                    aria-label="Password"
                  />
                </div>
                <div class="col-lg-12 my-3">
                  <label>
                    <small style={{ color: "#262626" }}>Gender*</small>
                  </label>
                  <select
                    class="form-select log-f-border"
                    aria-label="Default select example"
                  >
                    <option selected value="1">
                      Male
                    </option>
                    <option value="2">Female</option>
                  </select>
                </div>
                <button
                  onClick={submitHandle}
                  class="btn text-center mt-3 w-100 fw-bold log-btn"
                >
                  Create Account
                </button>
                <div class="my-1">
                  <small>
                    By clicking Register I agree to the Terms & Conditions and
                    Privacy Policy.
                  </small>
                </div>
                <div class="text-center my-3">
                  <Link to={"/login"} class=" f-c-acc">
                    Already have an account?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reg;

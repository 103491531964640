import { withRouter, Link } from "react-router-dom";
import Oconfirm from "../image/icons/orderc.png";
import Navbar from "./navbar.js";
import Navbar2 from "./navbar2.js";
function Confirm() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <center class="mt-5">
        <img src={Oconfirm} class="orderc mt-5" />
        <br></br>
      </center>
    </>
  );
}

export default Confirm;

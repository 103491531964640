import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import increment from "../actions";
import Logo from "../itphone_logo.png";

function Navbar2() {
  const [log, setLog] = useState(false);
  const [active, setActive] = useState("");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [modalOpen, setModalopen] = useState(false);

  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandList(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      const data = new FormData();
      data.append("token", localStorage.getItem("token"));
      fetch("https://server.itphonestore.com/itphone/auth", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setLog(res.message);

          var name = res.result[0].fname.substr(
            0,
            res.result[0].fname.indexOf(" ")
          );

          setActive(name);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  document.body.addEventListener("click", function (event) {
    setShow(false);
  });

  return (
    <>
      <nav class="navbar navbar-expand-lg sticky-top mobc moboff">
        <div class="container-fluid row px-lg-5 px-0 mx-0">
          <div class="col-lg-2 col-1">
            <img
              onClick={() => {
                setModalopen(!modalOpen);
                console.log(modalOpen);
              }}
              src={require("../image/icons/hamburger.png")}
              class="user-btn-logom"
              alt=""
              srcset=""
            />
          </div>
          <div class="col-lg-4 col-5">
            <Link to={"/"}>
              <img src={Logo} class="navbar-logo-1" alt="" srcset="" />
            </Link>
          </div>
          <div class="col-lg-6 col-6 text-end">
            {log ? (
              <Link to={"/account"}>
                <button class="btn userbtn user-btn mx-0">
                  <div class="d-flex justify-content-between ">
                    <p class="navusername fw-bold m-0 mx-1">{active}</p>
                    <img
                      src={require("../image/icons/user.png")}
                      class="user-btn-logo"
                      alt=""
                      srcset=""
                    />
                  </div>
                </button>
              </Link>
            ) : (
              <Link to={"/login"}>
                <button class="btn userbtn user-btn mx-0">
                  <div class="d-flex justify-content-between ">
                    <p class="navusername m-0 mx-3">Log In {modalOpen}</p>
                    {/* <img
                      src={require("../image/icons/user.png")}
                      class="user-btn-logo"
                      alt=""
                      srcset=""
                    /> */}
                    <i class="fa-regular fa-user user-btn-logo"></i>
                  </div>
                </button>
              </Link>
            )}
          </div>
        </div>
      </nav>

      <div
        onClick={() => {
          setModalopen(!modalOpen);
        }}
        className={
          modalOpen ? "mobileSideBack modalBackActive" : "mobileSideBack"
        }
      ></div>

      <div className={modalOpen ? "mobileSide modalActive" : "mobileSide"}>
        <div class="navbar px-4">
          <Link to={"/"}>
            <img src={Logo} class="navbar-logo-1" alt="" srcset="" />
          </Link>
        </div>
        <div class="p-3 border-bottom">
          <Link class="nrls" to={"/newrelease"}>
            <p class="a-text fw-bold mb-0">
              New Release<span style={{ color: "#ffce00" }}>*</span>
            </p>
          </Link>
        </div>
        <div class="p-3 border-bottom">
          <Link class="nrls" to={"/wholesale"}>
            <p class="a-text fw-bold mb-0">Wholesale</p>
          </Link>
        </div>
        <div class="catside bangla_font">
          <ul class="card-body border-cate pb-1">
            <p class="a-text fw-bold">Category</p>
            {category.map((item) => (
              <div to={"/category/" + item.id} class="my-2 cardcm">
                <div class="row align-items-center p-2 my-1 border-top">
                  <div className="d-flex justify-content-between">
                    <Link
                      onClick={() => {
                        setModalopen(!modalOpen);
                      }}
                      to={"/category/" + item.cname + "/all"}
                      class="col-lg-9 n-text navlinkc"
                    >
                      {item.cname}
                    </Link>

                    <div>
                      <a
                        data-bs-toggle="collapse"
                        href={
                          "#" +
                          item.cname.replace(/\s+/g, "-").replace("&", "-")
                        }
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <i
                          className="fa fa-angle-down"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </div>
                  </div>

                  <div
                    class="collapse"
                    id={item.cname.replace(/\s+/g, "-").replace("&", "-")}
                  >
                    <div>
                      {brandList.map((brand) => (
                        <div class="m-2">
                          {brand.brandCate == item.cname ? (
                            <Link
                              onClick={() => {
                                setModalopen(!modalOpen);
                              }}
                              to={
                                "/category/" +
                                brand.brandCate +
                                "/" +
                                brand.brandName
                              }
                              class="col-lg-9 n-text navlinkc"
                            >
                              <img
                                src={
                                  "https://itphonestore.com/image/" +
                                  brand.brandImage
                                }
                                class="catimg my-2"
                              />
                              <small>{brand.brandName}</small>
                            </Link>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div class="mt-3">
          <div class="p-3">
            <Link to={"/service-center"}>
              <small class="navtexts">About IT Phone</small>
            </Link>
            <Link to={"/policy"}>
              <small class="m-2 navtexts">Policy</small>
            </Link>
            <Link to={"/terms"}>
              <small class="m-2 navtexts">Terms and Conditions</small>
            </Link>
            <Link to={"/contact"}>
              <small class="m-2 navtexts">Contact Us</small>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar2;

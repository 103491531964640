import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Footer from "./footer";
import { useState, useEffect } from "react";
import Frontimg from "../image/front-img.jpg"
function About() {
    const [pageXOffset, setPageXOffset] = useState('');
    const [pageYOffset, setPageYOffset] = useState('');
    useEffect(() => {
        // setInterval(() => {
        //     document.getElementById('caro').scrollBy(300, 0);
        //   }, 3000);
        let width = window.screen.width;

        if (width < 400) {
            setInterval(() => {
                document.getElementById('caro').scrollBy(415, 0);
            }, 3000);
        } else {
            setInterval(() => {
                document.getElementById('caro').scrollBy(410, 0);
            }, 3000);
            if (window.pageYOffset != undefined) {
                return [pageXOffset, pageYOffset];
            }

        }
    }, []);
    return (
        <>
            <Navbar />
            <Navbar2 />
            <div class="container my-lg-3 my-2 mb-5 pb-3">
                <div class="mb-4 mt-5">
                    <h3 className="py-1 " style={{ color: "#555" }}><b>About</b> Iqbal & Tareq Mobile Phones LLC.</h3>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <p style={{ textAlign: "justify" }}>
                            IQBAL & TAREQ MOBILE PHONE LLC started its journey Since 2005 as a small family business. In 2007 started focusing and specializing in international mobile communications and telecommunications build relation with international suppliers and customers  which have gone by full of successes, grew into a company having a clientele and operations worldwide.
                            Over the last several years, IQBAL & TAREQ MOBILE PHONE LLC have developed into a reliable wholesaler for all the known brands and big names of mobile communications products.
                            The company headquarter are located in the heart of Dubai with its own large store also operate its international shipment from Dubai Airport FZE. Most of the freight forwarders  located in Airport FZE are authorized to operate international shipment.
                            In 2017, the company started its new branch in sultanate of Oman name AK INTERNATIONAL located in Ruwi. <br />
                            Our company's primary direction is the export-import of all cellphones, tablets, smart watches and other electronic devices.
                            Our qualified personnel are young, characterized by efficiency, high responsibility, and attention to our client’s inquiries.
                        </p>
                        <p>
                            <b>Mohammed Iqbal Hossain</b> <br />
                            CEO, <br />
                            Iqbal & Tareq Mobile Phones LLC.
                        </p>
                    </div>
                    <div className="col-lg-5 text-center">
                        <img src={Frontimg} class="img-fluid rounded-top front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-5 text-center">
                    <h2 class="fw-bold">
                        Our Warehouse
                    </h2>
                    <div className="container-fluid position-relative">
                        <div class="d-flex" id="caro">
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-1-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-2-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-3-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-4-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-5-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-6-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-7-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-8-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-9-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-10-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-11-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-12-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-13-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-15-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-16-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-17-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-18-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-19-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-20-min.jpeg')} className="caro-size" />
                            </div>
                            <div className="caro-img" >
                                <img src={require('../image/slider/slider-21-min.jpeg')} className="caro-size" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About;
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Footer from "./footer";
function Terms() {
    return (
        <>
            <Navbar />
            <Navbar2 />
            <div class="container my-lg-3 my-2 mb-5 pb-3 pt-3">
                <h4><b> 1.	Terms and Conditions</b></h4>
                <p><b>1.1 Iqbal & Tareq Mobile Phones LLC site E-commerce purchases</b><br />
                    •	Product Display/Colors
                    The Iqbal & Tareq Mobile Phones LLC site attempts to display product images shown on the site as accurately as possible. However, we cannot guarantee that the color you see matches the product color, as the display of the color depends, in part, upon the monitor you are using.
                    <br />•	Pricing and Selection
                    Online prices and selection generally follow those in our stores, but may vary. Prices and offers generally are subject to change at any time but will not be changed on any order already placed by you. Prices for products exclude delivery costs, which if applicable will be added to the Customer Invoice in accordance with our current pricing structure (available on request). It is agreed that all prices are inclusive of Value Added Tax (VAT). Where VAT is payable by us the agreed consideration for the supply shall include the amount of VAT payable applicable at the prevailing rate at the time the supply is made as a result of applicable legislation in the United Arab Emirates and the Kingdom of Saudi Arabia (as applicable).
                    <br />•	Errors on Our Site
                    Mispricing and other errors (such as mistakes in product descriptions, availability or any similar matter) will be corrected where discovered, and the Iqbal & Tareq Mobile Phones LLC site reserves the right in our complete discretion to cancel any effected orders and refund any amounts paid in full, including after an order has been submitted and whether or not the order has been finally confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, Iqbal & Tareq Mobile Phones LLC will issue a credit to your credit card account in the amount of the charge. Individual bank policies will dictate when this amount is credited to your account.
                    <br /><b>•	Payment Methods:</b><br />
                    <b>You may pay for your orders with the following methods:</b>
                    <br />•	Credit/Debit card Online (card will be charged when stock is allocated to your order)
                    <br />•	Cash/Credit or Debit card on Delivery (card will be charged immediately on delivery)
                    When paying for a pre-order with a credit/debit card you will be charged at the time you place your pre-order.
                    Refunds due to cancellation by Iqbal & Tareq Mobile Phones LLC in a retail store or the Iqbal & Tareq Mobile Phones LLC site for permitted reasons will always follow the same method as the original payment:
                    <br />•	If an order is placed by credit/debit card, the money will be automatically credited back to your bank. This usually takes 10-20 days after Iqbal & Tareq Mobile Phones LLC has initiated the refund, depending on your bank.
                    <br /> •	For any permitted refunds initiated by you, the refund will follow the same method as the payment and only the value of the item will be refunded and not the shipping charges.
                    <b>Refund processing is subject to timelines from your bank, and Iqbal & Tareq Mobile Phones LLC will not be responsible for any delays once the refund has been issued to the bank from Iqbal & Tareq Mobile Phones LLC. Please contact your card issuer for more information.</b>
                    <br /><b>•	Using Promotional Codes</b><br />
                    Unlike credit or debit cards, Promotional Codes used on Digital Coupons are debited at the time an order is placed. Digital Coupons will automatically be redeemed to purchase totals, up to the total purchase amount. Digital Coupons must be used prior to their noted expiration date and within a single transaction. Digital Coupons cannot be replaced if lost, stolen, deleted, or if you cancel or return your purchase. In the event of a reduction in price below the coupon value, the excess value of the coupon will not be refunded. Digital coupons and vouchers can be used only on the Iqbal & Tareq Mobile Phones LLC site within the applicable time period as communicated.
                    <br /><b>•	Validating Your Order</b><br />
                    We reserve the right to reject any order you place with us. After you place an order using our shopping cart, we will check the information you give us for validity, by verifying your method of payment or shipping address. We reserve the right to reject any order you place with us, and/or to limit quantities on any order, without giving any reason. If we reject your order, we will attempt to notify you using the e-mail address you have given us with the order.
                    Your credit/debit card will normally not be charged if we reject an order, but we will process a refund if the charge has been made against your card.
                    <br /><b>•	Order Acceptance/Confirmation</b><br />
                    Your receipt of an electronic or other form of order confirmation does not signify our final acceptance of your order, nor does it constitute confirmation of our offer to sell. The Iqbal & Tareq Mobile Phones LLC site reserves the right at any time after receipt of your order to accept or decline your order for any reason.
                    <br /><b>•	Order Limitations/Limited Quantities</b><br />
                    Iqbal & Tareq Mobile Phones LLC may, at our own discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by the same Iqbal & Tareq Mobile Phones LLC site account, the same credit card, and/or that use the same billing and/or shipping address. In the event we make a change to an order, we will attempt to notify you by contacting the e-mail and/or billing address provided at the time the order was made. We reserve all rights to limit or prohibit sales if we suspect you are a reseller.
                    <br /><b>•	Shipping Costs and Fees</b><br />
                    <br /><b>Shipping costs are based on a combination of the following:</b>
                    <br /> •	Weight and dimensions of your package;
                    <br />•	Method of shipment (ground, air, sea, rail, etc.);
                    <br />•	Location you are shipping from (city, state, country); and
                    <br />•	Location you are shipping to (the destination country).
                    <br />We offer free shipping within the UAE, for orders of AED 500 and above. Shipping costs to other countries will be calculated on your shopping cart during checkout.
                    Costs can be considerably higher for international destinations, and generally do not include additional charges such as duties, taxes, and customs clearance fees. For example, international rates may include pickup and door-to-door delivery. Extended Area Surcharges may apply depending on the international location and are not included in the shipping fees. This should be paid by the customer receiving the items.
                    Those items for shipment to countries outside of the UAE may be subject to taxes, customs duties and fees levied by the destination country. The recipient of the shipment is the importer of record in the destination country and is responsible for all Import Fees.
                    <br /><b>•	Shipping Mode</b>
                    <br /><b> - Depending on the location, we use our own or third-party courier services.</b>
                    <br />•	Delivery Time Frames
                    <br />•	Dubai and Sharjah - within 24-48 hours.
                    <br />•	All other Emirates - within 24-72 hours.
                    <br />•	Within the GCC: 5-7 days (depending on clearance times through the relevant authorities).
                    <br />•	All other international destinations outside the GCC: up to 10 days (depending on clearance times through the relevant authorities).
                    <br />•	Delivery locations are determined by the product page when orders are made.
                    <br />All timeframes given exclude Fridays and any applicable public holidays. Iqbal & Tareq Mobile Phones LLC is not liable for any damages caused by delay in delivery or failure to deliver of any kind.
                    <br />•	Cancellation Policy
                    <br />If we receive a cancellation notice and the order has not been shipped, we shall cancel the order and refund the entire amount. The refund process will take place as noted in the Payments section. We will not be able to cancel orders that have already been shipped. You agree not to dispute our decision and accept this Cancellation Policy.
                    <br /><b>1.2 Preorder of new product launches</b>
                    <br />•	Applicable conditions for pre-order are as per the specific manufacturer’s instructions for any given pre-order, which always will consider the available stock and status of the relevant market.
                    <br />•	Delivery timelines will be communicated during the launch of any given pre-order, and may be updated during the period as per demand and supply, at the discretion of the manufacturer.
                    <br />•	You are only eligible for the offer communicated at the time of your particular pre-order, whether purchased online or purchased in a retail store.
                    <br />•	Iqbal & Tareq Mobile Phones LLC reserves the right for final allocation of any pre-order stock as per our sole discretion at all times.
                    <br />•	Refunds due to cancellation by Iqbal & Tareq Mobile Phones LLC will always follow the same method as the original payment.
                    <br /><b>2.	Additional Terms<br />
                        2.1 Termination of Use</b>
                    <br />Iqbal & Tareq Mobile Phones LLC may, in its sole discretion, terminate your account or your use of the Iqbal & Tareq Mobile Phones LLC site at any time. You are personally liable for any orders that you place or charges that you incur prior to termination. The Iqbal & Tareq Mobile Phones LLC site reserves the right to change, suspend or discontinue all or any aspects of the Iqbal & Tareq Mobile Phones LLC site at any time without prior notice.
                    <br /><b>2.2 Customer Rights</b>
                    <br />The Consumer Code of Rights was issued under UAE Federal Law (24) 2006 by the Ministry of Economy (MoE).
                    <br /> <b>2.3 Electronic Communications</b>
                    <br />When you use the Iqbal & Tareq Mobile Phones LLC site, or send e-mails, text messages, and other communications from your desktop or mobile device to us, you may be communicating with us electronically. You consent to receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and messages on this site or and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                    <br /><b>2.4 Intellectual Property – Licenses, COPYRIGHT and Trademarks</b>
                    <br />All content included in or made available through the Iqbal & Tareq Mobile Phones LLC site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software is the property of Iqbal & Tareq Mobile Phones LLC or another third party and is protected by local and international intellectual property laws. The compilation of all content included in or made available through the Iqbal & Tareq Mobile Phones LLC site is the exclusive property of Iqbal & Tareq Mobile Phones LLC and protected by local and international intellectual property laws.
                    <br /> In addition, graphics, logos, page headers, button icons, scripts, and service names included in or made available through the Iqbal & Tareq Mobile Phones LLC site are trademarks or trade dress of Iqbal & Tareq Mobile Phones LLC and other third parties and may not be used in connection with any other product or service or in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Iqbal & Tareq Mobile Phones LLC and its third-party partners. All other trademarks not owned by Iqbal & Tareq Mobile Phones LLC that appear on the Iqbal & Tareq Mobile Phones LLC site are the property of their respective owners.
                    <br /><b>2.5 Reviews, Comments, Communications, and other Content</b>
                    <br />You may submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages. Iqbal & Tareq Mobile Phones LLC reserves the right (but not the margin: 10px 0px; obligation) to remove or edit such content.
                    <br /> If you do post content or submit material, and unless we indicate otherwise, you grant Iqbal & Tareq Mobile Phones LLC a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the world in any media. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Iqbal & Tareq Mobile Phones LLC for all claims resulting from content you supply. Iqbal & Tareq Mobile Phones LLC has the right but not the obligation to monitor and edit or remove any activity or content. Iqbal & Tareq Mobile Phones LLC takes no responsibility and assumes no liability for any content posted by you or any third party.
                    <br /><b>2.6 Disputes and Applicable Law</b>
                    <br />These Terms shall be interpreted and governed by the laws of the United Arab Emirates. Each party agrees to submit to the jurisdiction of the courts of Dubai and to waive any objections based upon venue. Iqbal & Tareq Mobile Phones LLC may select to pursue any dispute in any court as it determines in its sole discretion.
                </p>
            </div>
            <Footer />
        </>
    )
}
export default Terms
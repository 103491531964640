import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Footer from "./footer";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Contact() {
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Message, setMessage] = useState('');

    function submitHandle() {
        const data = new FormData();
        data.append("name", Name);
        data.append("email", Email);
        data.append("phone", Phone);
        data.append("message", Message);
        fetch("https://server.itphonestore.com/itphone/submitInbox", {
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                if (res.message == null) {
                    document.getElementById("inbox").reset();
                    toast.success("Message sent");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <Navbar />
            <Navbar2 />
            <ToastContainer />
            <div>
                <div class="container my-lg-3 my-2 mb-5 pb-3">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-lg-5 col-12 mt-4 pt-3 text-capitalize">

                            <h4 className="fw-bold mb-2 ">Sales Support</h4>
                            <p class="card-text mb-1 text-secondary">Md nurul islam : <b>+971554083760</b></p>
                            <p class="card-text mb-1 text-secondary">Rabiul hussain (Farid) : <b>+971508781204</b></p>
                            <p class="card-text mb-1 text-secondary">Mohammed arman : <b>+971552346493</b></p>
                            <h4 className="fw-bold mt-4">Service Support</h4>
                            <p class="card-text mb-1 text-capitalize text-secondary">Mohammed tutul : <b>+971556073659</b></p>
                            <h4 className="fw-bold mt-4">Email Support</h4>
                            <p class="card-text mb-1 text-lowercase text-secondary"> itphones846@hotmail.com</p>
                            <h4 className="fw-bold mt-4">Address </h4>
                            <p class="card-text mb-1 text-secondary">P.O.Box: 172201 Al Musallah Road Deira Dubai - U.A.E.</p>

                        </div>
                        <div className="col-lg-5 col-12 px-lg-5 px-4">
                            <h4 className="mt-2 res-cont"> Contact Us</h4>
                            <form id="inbox">
                                <div class="mb-3">
                                    <label for="name" class="form-label">
                                        Name
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        type="text"
                                        class="form-control border-secondary"
                                        id="name"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="Email" class="form-label">
                                        Email
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        type="email"
                                        class="form-control border-secondary"
                                        id="Email"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="Phone" class="form-label">
                                        Phone
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                        type="number"
                                        class="form-control border-secondary"
                                        id="Phone"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="Message" class="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                        name="Message"
                                        id="Message"
                                        rows="2"
                                        class="form-control border-secondary"
                                    ></textarea>
                                </div>
                            </form>
                            <button
                                onClick={() => {
                                    submitHandle();
                                }}
                                type="button"
                                class="btn bcolor w-100 text-light"
                            >
                                Send
                            </button>
                        </div>

                    </div>
                </div>
                <div class="container-fluid p-0" style={{ height: "400px" }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.9038917349267!2d55.3004789260057!3d25.273818283184617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d2cbb80c91b%3A0xae403f5dbe93df1d!2sIQBAL%20%26%20TAREQ%20MOBILE%20PHONE%20LLC!5e0!3m2!1sen!2sbd!4v1674908580968!5m2!1sen!2sbd"
                        width="100%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <h1 className="position-absolute top-50 start-50 translate-middle">map will be here</h1> */}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Contact;
import { useEffect, useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bottomnav from "../bottomnav";
import Navbar from "../navbar";
import Navbar2 from "../navbar2";

function PersonalInfo() {
  let history = useHistory();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [allprod, setAllprod] = useState([]);
  const [coun, setCoun] = useState(0);
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      const data = new FormData();
      data.append("token", localStorage.getItem("token"));
      fetch("https://server.itphonestore.com/itphone/fetchinfo", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setFname(res.message[0].fname);
          setLname(res.message[0].lname);
          setEmail(res.message[0].email);
          setPhone(res.message[0].phone);
          setAddress(res.message[0].address);
        })
        .catch((err) => console.log(err));
    }
  }, [coun]);

  function UpdateProfile() {
    if (!edit) {
      const data = new FormData();
      data.append("fname", fname);
      data.append("token", localStorage.getItem("token"));

      fetch("https://server.itphonestore.com/itphone/updateProfile", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          toast.success("তথ্য পরিবর্তন করা হয়েছে", {
            icon: "✅",
          });
          setCoun(coun + 1);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div class="container-fluid">
        {/* <div class="d-flex">
          <i
            onClick={() => history.goBack()}
            class="fa fa-arrow-left m-2"
            style={{ cursor: "pointer" }}
          ></i>
          <p class="fs-5 pb-3">ব্যক্তিগত তথ্য</p>
        </div> */}

        <div class="row mx-0 my-2 pb-3 border bg-light">
          <p class="fs-5 py-1 border-bottom">Personal Info</p>
          <div class="col-lg-8">
            <form class="px-2">
              <div class="col-lg-12 mb-3">
                <p for="inputfullname" class="form-label pb-2">
                  Your Name
                </p>
                <input
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  class="form-control form-control-input"
                  id="inputfullname"
                  readOnly={edit}
                />
              </div>

              <div class="col-lg-12 mb-3">
                <p for="inputEmail4" class="form-label pb-2">
                  E-mail
                </p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  class="form-control form-control-input"
                  id="inputEmail4"
                  readOnly="false"
                />
              </div>
              <div class="col-lg-12">
                <label for="inputnumber" class="form-labeld pb-2">
                  Mobile Number
                </label>
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  class="form-control form-control-input"
                  id="inputnumber"
                  readOnly="false"
                />
              </div>
            </form>
          </div>
          <div class="col-lg-8 my-4 mb-2 px-0">
            <a
              onClick={() => {
                setEdit(!edit);
                UpdateProfile();
              }}
              type="submit"
              class="btn btn-edit-profile fs-6"
            >
              <i class="fa-solid fa-user-pen mx-2"></i>
              Update Info
            </a>
          </div>

          <div class="col-lg-8 px-0">
            <a type="submit" class="btn btn-edit-profile fs-6">
              <i class="fa-solid fa-key mx-2"></i>
              Change Password
            </a>
          </div>
        </div>
      </div>

      <Bottomnav />
    </>
  );
}

export default PersonalInfo;

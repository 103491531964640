import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import { useHistory, withRouter, Link } from "react-router-dom";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Bottomnav from "./bottomnav";
import Footer from "./footer";
import decrement from "../actions/decrement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import nullify from "../actions/nullify";

function Cart() {
  let history = useHistory();
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);
  const [coun, setCoun] = useState(0);
  const [total, setTotal] = useState(0);
  const [dlist, setDlist] = useState([]);
  const [did, setDid] = useState("");
  const [load, setLoad] = useState(false);
  const [allprod, setAllprod] = useState([]);
  const [show, setShow] = useState(false);
  const [otype, setOtype] = useState("shop");
  const [note, setNote] = useState("");

  useEffect(() => {
    console.log(localStorage.getItem("items"));
    if (localStorage.getItem("items") != null) {
      setItems(JSON.parse(localStorage.getItem("items")));
      var i = 0;
      JSON.parse(localStorage.getItem("items")).map((item) => {
        i = i + item.quantity * item.price;
      });

      setTotal(i.toFixed(2));
    }

    const data = new FormData();
    data.append("token", localStorage.getItem("token"));

    fetch("https://server.itphonestore.com/itphone/dlist", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setDlist(res.message);
      })
      .catch((err) => console.log(err));

    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setAllprod(res.message);
      })
      .catch((err) => console.log(err));
  }, [coun]);

  function upquan(index, i) {
    i++;
    var items = JSON.parse(localStorage.getItem("items"));
    items[index].quantity = i;
    localStorage.setItem("items", JSON.stringify(items));
    setCoun(coun + 1);
  }

  function upquan2(index, i) {
    i--;
    if (i < 1) {
      i = 1;
    }
    var items = JSON.parse(localStorage.getItem("items"));
    items[index].quantity = i;
    localStorage.setItem("items", JSON.stringify(items));
    setCoun(coun + 1);
  }

  function orderprod() {
    if (localStorage.getItem("token") == null) {
      history.push("/login");
      return false;
    }

    if (did == "") {
      toast.warning("Confirm your delivery address", {
        icon: "🛵",
      });
      return false;
    }

    if (localStorage.getItem("items") == null) {
      toast.warning("Your cart is empty", {
        icon: "🛵",
      });
      return false;
    }

    setLoad(true);

    var x = Number(total);
    const data = new FormData();
    data.append("status", "Order Placed");
    data.append("total", x);
    data.append("did", did);
    data.append("token", localStorage.getItem("token"));
    data.append("otype", otype);
    data.append("note", note);
    // data.append("color", color);
    data.append("items", localStorage.getItem("items"));

    fetch("https://server.itphonestore.com/itphone/orderprod", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
        if (res.message == null) {
          localStorage.removeItem("items");
          localStorage.removeItem("count");
          setLoad(false);
          setCoun(coun + 1);
          dispatch(nullify());
          history.push("/confirm");

          toast.success("Product ordered successfully", {
            icon: "🛒",
          });
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />
      <div class="container-fluid px-lg-5 mb-lg-0 mb-5 bg-light">
        <div class="row">
          <div class="col-lg-9 col-12 moboff">
            <div class="col-lg-12 card-body bg-white my-lg-4 my-2 mb-lg-1">
              <p class="my-auto fs-5">Shopping Cart ({counter})</p>
            </div>
            <div class="col-lg-12 card-body bg-white mb-1">
              {items.length == 0 ? (
                <div class="text-center">
                  <img
                    src={require("../image/icons/no_product.gif")}
                    class="noproduct pt-0 mt-0"
                  />
                  <p style={{ marginTop: -30 }}>
                    Add product to shopping cart & place your order!
                  </p>
                </div>
              ) : null}
              {items.map((item, index) => (
                <div class="row mb-1 cartitem py-2">
                  <div class="col-lg-5">
                    <div class="d-flex">
                      {allprod.map((y) => (
                        <>
                          {y.id == item.id ? (
                            <img
                              src={"https://itphonestore.com/image/" + y.pimage}
                              class="cart-pr-img"
                              alt=""
                              srcset=""
                            />
                          ) : null}
                        </>
                      ))}
                      <div class="mx-4 my-auto">
                        <div class="cart-pro-name">{item.name}</div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span>Color:</span>
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: 25,
                                height: 25,
                                margin: 4,
                                border: "2px solid #ddd",
                                // cursor: "pointer",
                                borderRadius: 50,
                              }}
                            ></div>
                          </div>
                        </div>
                        <p class="text-warning fs-5 mtop">AED {item.price}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 my-auto">
                    <div class="input-group cart-i-d-margin">
                      <input
                        type="button"
                        value="-"
                        class="button-minus text-muted rounded-circle icon-sm mx-3"
                        data-field="quantity"
                        onClick={() => upquan2(index, item.quantity)}
                      />
                      <input
                        type="number"
                        value={item.quantity}
                        placeholder="1000 gm"
                        name="quantity"
                        class="quantity-field  text-center w-25"
                        readOnly
                      />
                      <input
                        type="button"
                        value="+"
                        class="button-plus text-muted rounded-circle icon-sm  mx-3"
                        data-field="quantity"
                        onClick={() => upquan(index, item.quantity)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 my-auto">
                    <div class="text-end cart-trash">
                      <i
                        onClick={() => {
                          dispatch(decrement(index));
                          setCoun(coun + 1);
                        }}
                        class="fa-solid fa-trash-can text-muted cur"
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {localStorage.getItem("token") == null ? (
              <>
                <div class="col-lg-12 card-body bg-white my-4">
                  <p class="my-auto fs-5">Delivery Address</p>
                  <div class="card freedelivery p-2 my-2 mb-3">
                    <small>Get Free Delivery In Dubai</small>
                  </div>

                  <div class="d-flex justify-content-between mt-3">
                    <p class="">
                      No delivery address added. Add delivery location to place
                      order.
                    </p>
                    <Link to={"/login"} class=" modal-decoration">
                      Log In First
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div class="col-lg-12 card-body bg-white my-4">
                <p class="my-auto fs-5">Delivery Address</p>
                <div class="card freedelivery p-2 my-2 mb-3">
                  <small>Get Free Delivery In Dubai</small>
                </div>
                {dlist.length == 0 ? (
                  <div class="d-flex justify-content-between mt-3">
                    <p class="">
                      No delivery address added. Add delivery location to place
                      order.
                    </p>
                    <a
                      href="#"
                      class="modal-decoration"
                      data-bs-toggle="modal"
                      data-bs-target="#add-address"
                    >
                      Add Delivery Address
                    </a>
                  </div>
                ) : null}

                {dlist.length < 2 ? (
                  <div class="d-flex">
                    <p style={{ fontStyle: "italic" }}>
                      Want to change or add new address?
                    </p>
                    <Link to={"/addnewaddress/"} class="addnaddress px-2 mx-2">
                      Add New Address
                    </Link>
                  </div>
                ) : null}

                <div class="d-flex px-0">
                  {dlist.map((item) => (
                    <div
                      class={
                        did == item.id
                          ? "col-lg-5 col-12 p-2 card bg-light shadow-sm border-check"
                          : "col-lg-5 col-12 p-2 card bg-light"
                      }
                      style={{ marginRight: 20 }}
                      onClick={() => setDid(item.id)}
                    >
                      <p class="mb-1" style={{ fontSize: 14 }}>
                        <b>{item.name}</b>
                      </p>
                      <p class="mb-1" style={{ fontSize: 14 }}>
                        {item.phone}
                      </p>
                      <div class="d-flex  bg-white align-items-center py-1">
                        <div class="px-3">
                          <input
                            onChange={(e) => setDid(item.id)}
                            class="form-check-input"
                            type="checkbox"
                            checked={did == item.id ? true : false}
                          />
                        </div>
                        <div class="px-2">
                          <p class="fw-bold mb-0" style={{ color: "#ffa200" }}>
                            {item.label}
                          </p>
                          <p class="m-0">
                            <small>
                              {item.hname}, {item.sname}, {item.aname},{" "}
                              {item.street}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div class="col-lg-9 col-12 mobc">
            <div class="col-lg-12 col-12 card-body bg-white my-lg-4 my-2">
              <h5 class="my-auto fw-bold">Shopping Cart ({counter})</h5>
            </div>
            <div class="col-lg-12 card-body bg-white mb-1">
              {items.length == 0 ? (
                <div class="text-center">
                  <img
                    src={require("../image/icons/no_product.gif")}
                    class="noproduct pt-0 mt-0"
                  />
                  <p style={{ marginTop: -30 }}>
                    Add product to shopping cart & place your order!
                  </p>
                </div>
              ) : null}
              {items.map((item, index) => (
                <div class="row mb-1 cartitem py-2">
                  <div class="col-lg-5">
                    <div class="d-flex">
                      {allprod.map((y) => (
                        <>
                          {y.id == item.id ? (
                            <img
                              src={"https://itphonestore.com/image/" + y.pimage}
                              class="cart-pr-img"
                              alt=""
                              srcset=""
                            />
                          ) : null}
                        </>
                      ))}
                      <div class="mx-4 my-auto">
                        <div class="cart-pro-name">{item.name}</div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span>Color:</span>
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: 25,
                                height: 25,
                                margin: 4,
                                border: "2px solid #ddd",
                                // cursor: "pointer",
                                borderRadius: 50,
                              }}
                            ></div>
                          </div>
                        </div>

                        <p class="text-warning fs-4 mtop">AED {item.price}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 my-auto">
                    <div class="input-group cart-i-d-margin">
                      <input
                        type="button"
                        value="-"
                        class="button-minus text-muted rounded-circle icon-sm mx-3"
                        data-field="quantity"
                        onClick={() => upquan2(index, item.quantity)}
                      />
                      <input
                        type="number"
                        value={item.quantity}
                        placeholder="1000 gm"
                        name="quantity"
                        class="quantity-field  text-center w-25"
                        readOnly
                      />
                      <input
                        type="button"
                        value="+"
                        class="button-plus text-muted rounded-circle icon-sm  mx-3"
                        data-field="quantity"
                        onClick={() => upquan(index, item.quantity)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 my-auto">
                    <div class="text-end cart-trash">
                      <i
                        onClick={() => {
                          dispatch(decrement(index));
                          setCoun(coun + 1);
                        }}
                        class="fa-solid fa-trash-can text-muted cur"
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {localStorage.getItem("token") == null ? (
              <>
                <div class="col-lg-12 card-body bg-white my-4">
                  <h5 class="my-auto fw-bold">Delivery</h5>
                  <div class="card freedelivery p-2 my-2 mb-3">
                    <small>Get Free Delivery In Dubai</small>
                  </div>

                  <div class="mt-3">
                    <p class="">
                      No delivery address added. Add delivery location to place
                      order.
                    </p>
                    <Link to={"/login"} class=" modal-decoration">
                      <small>Log In First</small>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div class="col-lg-12 card-body bg-white my-4">
                <h5 class="my-auto fw-bold">Delivery</h5>

                <div class="card freedelivery p-2 my-2 mb-3">
                  <small>Get Free Delivery In Dubai</small>
                </div>
                {dlist.length == 0 ? (
                  <div class="d-flex justify-content-between mt-3">
                    <p class="">
                      No delivery address added. Add delivery location to place
                      order.
                    </p>
                  </div>
                ) : null}
                {dlist.length < 2 ? (
                  <Link to={"/addnewaddress/"}>Add Address</Link>
                ) : null}

                <div class="col-lg-12">
                  {dlist.map((item) => (
                    <div
                      class={
                        did == item.id
                          ? "col-12 p-2 my-1 card bg-light shadow-sm border-check"
                          : "col-12 p-2 my-1 card bg-light"
                      }
                      style={{ marginRight: 20 }}
                      onClick={() => setDid(item.id)}
                    >
                      <p class="mb-1">
                        <b>{item.name}</b>
                      </p>
                      <p class="mb-1">{item.phone}</p>
                      <div class="d-flex rounded shadow-sm bg-white align-items-center py-1">
                        <div class="px-3">
                          <input
                            onChange={(e) => setDid(item.id)}
                            class="form-check-input"
                            type="radio"
                            checked={did == item.id ? true : false}
                          />
                        </div>
                        <div class="px-2">
                          <p class="fw-bold mb-0" style={{ color: "#ffa200" }}>
                            {item.label}
                          </p>
                          <p class="m-0">
                            <small>
                              {item.hname}, {item.sname}, {item.aname},{" "}
                              {item.street}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div class="col-lg-3 col-12 mb-3">
            <div class="container-fluid px-0">
              <div class="card-body bg-white my-lg-4 my-0">
                <div class="d-flex">
                  <img
                    src={require("../image/icons/pay.png")}
                    class="webicon pt-0 mt-0"
                  />
                  <p class="fw-bold  mb-1 mx-2">Payment Method</p>
                </div>
                <div class="my-3 card p-2 bg-light ptype">
                  <div class="d-flex  align-items-center ">
                    <i
                      class="fa-solid fa-circle-check"
                      style={{ color: "#ffc200" }}
                    ></i>
                    <p
                      class="mb-0 mx-2"
                      style={{ fontWeight: 500, color: "#565656" }}
                    >
                      Cash on Delivery
                    </p>
                  </div>
                </div>

                <div class="d-flex mt-4">
                  <img
                    src={require("../image/icons/orders.png")}
                    class="webicon pt-0 mt-0"
                  />
                  <p class="fw-bold mx-2">Order Summary</p>
                </div>

                <div class="d-flex justify-content-between">
                  <p>Product Price</p>
                  <p>AED {total}</p>
                </div>
                <div class="d-flex justify-content-between">
                  <p>Delivery Fee</p>
                  <p style={{ textDecoration: "line-through" }}>AED 0</p>
                </div>
                <div class="mtop">
                  <hr />
                </div>
                <div class="d-flex justify-content-between">
                  <p class="fw-bold">Total</p>
                  <p class="text-warning">AED {Number(total) + Number(0)}</p>
                </div>

                <button
                  class="btn btn-success w-100"
                  onClick={orderprod}
                  disabled={load ? true : false}
                >
                  {load ? "Loading" : "Place Order"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottomnav />
      <Footer />
    </>
  );
}
export default withRouter(Cart);

import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Navbarp from "./productnav";
import Sidebar from "./sidebar";
import Bottomnav from "./bottomnav";
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Footer from "./footer";
import Filter from "../image/icons/sort.png";

function Category(props) {
  let history = useHistory();
  const cate = props.match.params.cate;
  const brandName = props.match.params.brandName;
  var active = window.location.pathname.replace("/category/", "");

  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [category, setCategory] = useState([]);
  const [plist, setPlist] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [hrange, setHrange] = useState("");
  const [erange, setErange] = useState("");
  const [update, setUpdate] = useState(0);
  const [bottomModal, setBottomModal] = useState(false);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, [cate]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandList(res.message);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    const data = new FormData();
    data.append("cate", cate);
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setPlist(res.message);
      })
      .catch((err) => console.log(err));
  }, [cate]);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />

      <div class="mobc px-0 mx-0">
        <div class="searchboxm2 py-2 border-bottom">
          <div class="border bg-white mx-2">
            <div class="input-group" style={{ position: "relative" }}>
              <span class="input-group-prepend ">
                <div class="input-group-text home-search">
                  <i
                    class="fa fa-search"
                    style={{ color: "#565656", fontSize: 14 }}
                  ></i>
                </div>
              </span>
              <span
                onClick={() => history.push("/search")}
                class="form-control form-control-search  mobsearch home-search"
                type="search"
              >
                Search Product . . .
              </span>
            </div>
          </div>
          <p class="my-1 px-2 bangla_font d-flex justify-content-between">
            <span class="catnamemob">{cate}</span>
            <span
              class="filterbox"
              onClick={() => {
                setBottomModal(!bottomModal);
              }}
            >
              Filter
              <img src={Filter} class="filtericon" />
            </span>
          </p>
        </div>
      </div>

      <div class="container-fluid my-lg-3 my-2 mb-5 pb-3">
        <div class="row">
          {/* <button onClick={()=> dispatch(increment())}>{counter}</button> */}

          <div class="testside moboff border-end" style={{ width: "20%" }}>
            <div class="catside bangla_font">
              <ul class="card-body border-cate pb-1">
                <p class="a-text fw-bold">Category</p>

                {category.map((item) => (
                  <div to={"/category/" + item.id} class="my-2 cardcm">
                    <div class="row align-items-center py-1">
                      <div className="d-flex justify-content-between">
                        <Link
                          to={"/category/" + item.cname + "/all"}
                          class={
                            cate == item.cname
                              ? "col-lg-9 b-text navlinkc"
                              : "col-lg-9 n-text navlinkc"
                          }
                        >
                          {item.cname}
                        </Link>

                        <div>
                          <a
                            data-bs-toggle="collapse"
                            href={
                              "#" +
                              item.cname.replace(/\s+/g, "-").replace("&", "-")
                            }
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            <i
                              className="fa fa-angle-down"
                              style={{ float: "right" }}
                            ></i>
                          </a>
                        </div>
                      </div>

                      <div
                        class="collapse"
                        id={item.cname.replace(/\s+/g, "-").replace("&", "-")}
                      >
                        <div>
                          {brandList.map((brand) => (
                            <div class="m-2">
                              {brand.brandCate == item.cname ? (
                                <Link
                                  to={
                                    "/category/" +
                                    brand.brandCate +
                                    "/" +
                                    brand.brandName
                                  }
                                  class={
                                    brandName == brand.brandName
                                      ? "col-lg-9 b-text navlinkc"
                                      : "col-lg-9 n-text navlinkc"
                                  }
                                >
                                  <img
                                    src={
                                      "https://itphonestore.com/image/" +
                                      brand.brandImage
                                    }
                                    class="catimg my-1"
                                  />
                                  {brand.brandName}
                                </Link>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
            <div class="card-body bg-light ">
              <p class="fw-bold">Price Filter</p>

              <div className="d-flex align-items-center">
                <input
                  value={hrange}
                  type="number"
                  onChange={(e) => {
                    setHrange(e.target.value);
                  }}
                  placeholder="Min"
                  className="filterInput"
                />
                <small>TO</small>
                <input
                  value={erange}
                  type="number"
                  onChange={(e) => {
                    setErange(e.target.value);
                  }}
                  placeholder="Max"
                  className="filterInput"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-9 catpr col-12">
            <div class="row col-lg-12 m-0 px-lg-3 px-0 py-lg-2 py-0">
              <p class="cat-text-m my-2 moboff bangla_font">
                <span class="" style={{ color: "#004f98" }}>
                  {category.map((item) => (
                    <>{item.id == cate ? item.cname : null}</>
                  ))}
                </span>
              </p>

              {plist.length == 0 ? <p>No Product Available</p> : null}

              {plist
                .filter((item) => {
                  if (
                    item.category.toLowerCase().includes(cate.toLowerCase())
                  ) {
                    if (brandName == "all") {
                      return item;
                    } else {
                      if (
                        item.brand
                          .toLowerCase()
                          .includes(brandName.toLowerCase())
                      ) {
                        return item;
                      }
                    }
                  }
                })
                .filter((item) => {
                  if (hrange == "") {
                    return item;
                  } else {
                    if (item.offer == 1) {
                      if (item.oprice > hrange && item.oprice < erange) {
                        return item;
                      }
                    } else {
                      if (item.price > hrange && item.price < erange) {
                        return item;
                      }
                    }
                  }
                })
                .map((item) => (
                  <div class="hpcart py-lg-2 py-1 px-lg-2 px-1">
                    <div class="card pb-3 productcard px-lg-4 px-3 pt-3">
                      <Link class="link" to={"/product/" + item.id}>
                        <img
                          src={"https://itphonestore.com/image/" + item.pimage}
                          class="card-img-top"
                          alt=""
                          srcset=""
                        />
                        <p class="mtop prname mt-3 mb-lg-2 mb-0 ">
                          {item.pname}
                        </p>

                        <p class="text-price fs-5 mb-lg-2 mb-0">
                          {item.offer == 1 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: 8,
                                  color: "#565656",
                                  fontWeight: 300,
                                  fontSize: 15,
                                }}
                              >
                                AED {item.price}
                              </span>
                              <span>AED {item.oprice}</span>
                            </>
                          ) : (
                            <span>AED {item.price}</span>
                          )}
                        </p>
                        <p class="m-0 fs-6 text-secondary">
                          <span>
                            <i class="fa-solid fa-star text-warning"></i>
                          </span>
                          <span class="mx-1">{item.star.toFixed(2)}</span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Bottomnav />

      <div
        class={bottomModal ? "bottomSlide bottomSlide-active" : "bottomSlide"}
      >
        <div className="container">
          <div className="d-flex justify-content-between p-2">
            <b>Price Filter</b>
            <i
              onClick={() => {
                setBottomModal(!bottomModal);
              }}
              className="fa fa-close"
              style={{ marginRight: 0, marginTop: 3 }}
            ></i>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-center">
            <input
              value={hrange}
              type="number"
              onChange={(e) => {
                setHrange(e.target.value);
              }}
              placeholder="Min"
              className="filterInputm"
            />
            <small class="mb-0">TO</small>
            <input
              value={erange}
              type="number"
              onChange={(e) => {
                setErange(e.target.value);
              }}
              placeholder="Max"
              className="filterInputm"
            />
          </div>
          <div class="text-center mt-3">
            <button
              onClick={() => {
                setHrange("");
                setErange("");
              }}
              className="btn  filterbtn"
            >
              Reset
            </button>

            <button
              onClick={() => {
                setBottomModal(!bottomModal);
              }}
              className="btn  filterbtn2"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Category);

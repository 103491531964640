import "./App.css";
import "./responsive.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./screens/home";
import Product from "./screens/product";
import Cart from "./screens/cart";
import Category from "./screens/category";
import Brandpage from "./screens/brandPage";
import Newrelease from "./screens/newRelease";

import Categorym from "./screens/categorym";
import Login from "./screens/login";
import Reg from "./screens/reg";
import Account from "./screens/account";
import Search from "./screens/search";
import Confirm from "./screens/confirm";
import WholeSale from "./screens/wholesale";
import About from "./screens/about";
import Contact from "./screens/contact";
import Return from "./screens/return";
import Terms from "./screens/terms ";



import Mobile from "./screens/mobileAccount/mobile";

function App() {
  return (
    <Router>



      <Mobile />



      <Route path="/confirm">
        <Confirm />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>

      <Route path="/product/:id">
        <Product />
      </Route>

      <Route path="/cart/">
        <Cart />
      </Route>

      <Route path="/login/">
        <Login />
      </Route>

      <Route path="/reg/">
        <Reg />
      </Route>
      <Route path="/search/">
        <Search />
      </Route>

      <Route path="/account/">
        <Account />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route path="/category/:cate/:brandName">
        <Category />
      </Route>

      <Route path="/brands/:brandName">
        <Brandpage />
      </Route>

      <Route path="/newrelease">
        <Newrelease />
      </Route>



      <Route path="/categorym/">
        <Categorym />
      </Route>
      <Route path="/wholesale">
        <WholeSale />
      </Route>
      <Route path="/service-center">
        <About />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/policy">
        <Return />
      </Route>

    </Router>
  );
}

export default App;

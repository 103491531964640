import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Sidebar from "./sidebar";
import Footer from "./footer";
import Bottomnav from "./bottomnav";
import { withRouter, Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";

function Home() {
  let history = useHistory();
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [product, setProduct] = useState([]);
  const [popular, setPopular] = useState([]);
  const [covers, setCovers] = useState([]);
  const [category, setCategory] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        const unique = [
          ...new Map(res.message.map((m) => [m.brandName, m])).values(),
        ];
        setBrandList(unique);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchflash", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setPopular(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/coverfetch", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCovers(res.message))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />
      <div class=" container-fluid mt-lg-3 py-2 mobc">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="navbar-search bg-white border">
              <div class="input-group" style={{ position: "relative" }}>
                <span class="input-group-prepend ">
                  <div class="input-group-text home-search">
                    <i
                      class="fa fa-search"
                      style={{ color: "#565656", fontSize: 14 }}
                    ></i>
                  </div>
                </span>
                <span
                  onClick={() => history.push("/search")}
                  class="form-control form-control-search mobsearch home-search"
                  type="search"
                >
                  Search Product . . .
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-lg-2">
        <div class="row">
          <div class="col-lg-12">
            <div
              id="home-car-id"
              class="carousel slide "
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                {covers.map((item, index) => (
                  <button
                    type="button"
                    data-bs-target="#home-car-id"
                    data-bs-slide-to={index}
                    class={index == 0 ? "active" : ""}
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                ))}
              </div>

              <div class="carousel-inner home-car">
                {covers.map((item, index) => (
                  <div
                    class={
                      index == 0 ? "carousel-item active" : "carousel-item"
                    }
                    data-bs-interval="3000"
                  >
                    <img
                      onClick={() => {
                        if (item.category != 0) {
                          history.push("/category/" + item.category);
                        }
                      }}
                      src={"https://itphonestore.com/image/" + item.photo}
                      class="d-block w-100 home-car"
                      alt="..."
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#home-car-id"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#home-car-id"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid moboff">
        <div class="row">
          <div class="col-lg-12">
            {category.map((cateList, index) => (
              <>
                <div class="phonestab border-bottom border-end border-top mt-3">
                  <div class="d-flex justify-content-between align-items-center border-start  border-bottom">
                    <h5 class="p-2 pb-0">{cateList.cname}</h5>
                    <Link
                      class="seeall mx-2"
                      to={"/category/" + cateList.cname + "/all"}
                    >
                      <p class="p-1 px-lg-3 px-2 mb-0">
                        See All
                        <i class="fa-sharp fa-solid fa-chevron-right mx-2"></i>
                      </p>
                    </Link>
                  </div>
                  <div class="d-flex flex-row">
                    {product
                      .filter((item) => {
                        if (item.category.toString().includes(cateList.cname)) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <>
                          {index < 5 ? (
                            <div to={"/product/" + item.id} class="hpcart2">
                              <div class="pb-0  productcard border-start px-lg-4 px-3 pt-3">
                                <Link class="link" to={"/product/" + item.id}>
                                  <img
                                    src={
                                      "https://itphonestore.com/image/" +
                                      item.pimage
                                    }
                                    class="card-img-top px-lg-4 px-0"
                                    alt=""
                                    srcset=""
                                  />
                                  <p class="mtop prname mt-lg-3 mt-1 mb-0">
                                    {item.pname} {index}
                                  </p>

                                  <p class=" text-price my-2 fw-bold">
                                    {item.offer == 1 ? (
                                      <>
                                        <span
                                          style={{
                                            textDecoration: "line-through",
                                            marginRight: 8,
                                            color: "#565656",
                                            fontWeight: 300,
                                            fontSize: 14,
                                          }}
                                        >
                                          AED {item.price}
                                        </span>
                                        <span>AED {item.oprice}</span>
                                      </>
                                    ) : (
                                      <span>AED {item.price}</span>
                                    )}
                                  </p>
                                </Link>
                                <p class="supplied">
                                  Supplied by <b>Iqbal & Tareq</b>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                  </div>
                </div>
                {index == 0 ? (
                  <div class="mt-3">
                    <img
                      src={require("../image/icons/web.png")}
                      class="promo1"
                      alt=""
                      srcset=""
                    />
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>

      <div class="container-fluid mobc">
        <div class="row">
          <div class="col-lg-12">
            {category.map((cateList, index) => (
              <>
                <div class="phonestab border mt-3">
                  <div class="d-flex justify-content-between align-items-center border-bottom">
                    <h5 class="p-2 pb-0">{cateList.cname}</h5>
                    <Link
                      class="seeall mx-2"
                      to={"/category/" + cateList.cname + "/all"}
                    >
                      <p class="p-1 px-lg-3 px-2 mb-0">
                        See All
                        <i class="fa-sharp fa-solid fa-chevron-right mx-2"></i>
                      </p>
                    </Link>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      {product
                        .filter((item) => {
                          if (
                            item.category.toString().includes(cateList.cname)
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => (
                          <>
                            {index < 4 ? (
                              <div to={"/product/" + item.id} class="col-6">
                                <div class="pb-0  productcard px-lg-4 px-3 pt-3">
                                  <Link class="link" to={"/product/" + item.id}>
                                    <img
                                      src={
                                        "https://itphonestore.com/image/" +
                                        item.pimage
                                      }
                                      class="card-img-top px-lg-4 px-0"
                                      alt=""
                                      srcset=""
                                    />
                                    <p class="mtop prname mt-lg-3 mt-1 mb-0">
                                      {item.pname.substring(0, 38)}
                                      {item.pname.length > 38 ? "..." : ""}
                                    </p>

                                    <p class=" text-price my-2 fw-bold">
                                      {item.offer == 1 ? (
                                        <>
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              marginRight: 8,
                                              color: "#565656",
                                              fontWeight: 300,
                                              fontSize: 14,
                                            }}
                                          >
                                            AED {item.price}
                                          </span>
                                          <span>AED {item.oprice}</span>
                                        </>
                                      ) : (
                                        <span>AED {item.price}</span>
                                      )}
                                    </p>
                                  </Link>
                                  <p class="supplied">
                                    Supplied by <b>Iqbal & Tareq</b>
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                {index == 0 ? (
                  <div class="mt-3">
                    <img
                      src={require("../image/icons/web.png")}
                      class="promo1"
                      alt=""
                      srcset=""
                    />
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>

      <div class="container-fluid moboff">
        <div class="row">
          <div class="col-lg-12">
            <div class="phonestab border-bottom border-end border-top my-3">
              <div class="d-flex justify-content-between align-items-center border-start border-bottom">
                <h5 class="p-2 pb-0">Shop By Brand</h5>
                <Link to={"/brands/all"} class="seeall mx-2">
                  <p class="p-1 px-lg-3 px-2 mb-0">
                    See All
                    <i class="fa-sharp fa-solid fa-chevron-right mx-2"></i>
                  </p>
                </Link>
              </div>
              <div class="d-flex flex-row align-items-center">
                {brandList
                  .filter((item, index) => {
                    if (index < 5) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <Link
                      to={"/brands/" + item.brandName}
                      class="branditem text-center border-start py-3"
                    >
                      <img
                        src={
                          "https://itphonestore.com/image/" + item.brandImage
                        }
                        class="brandl"
                        alt=""
                        srcset=""
                      />
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mobc">
        <div class="row">
          <div class="col-lg-12">
            <div class="phonestab border-bottom border-end border-top my-3">
              <div class="d-flex justify-content-between align-items-center border-start border-bottom">
                <h5 class="p-2 pb-0">Shop By Brand</h5>
                <Link to={"/brands/all"} class="seeall mx-2">
                  <p class="p-1 px-lg-3 px-2 mb-0">
                    See All
                    <i class="fa-sharp fa-solid fa-chevron-right mx-2"></i>
                  </p>
                </Link>
              </div>
              <div class="d-flex flex-row align-items-center">
                {brandList
                  .filter((item, index) => {
                    if (index < 4) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <Link
                      to={"/brands/" + item.brandName}
                      class="branditem text-center border-start py-3"
                    >
                      <img
                        src={
                          "https://itphonestore.com/image/" + item.brandImage
                        }
                        class="brandl"
                        alt=""
                        srcset=""
                      />
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-light">
        <div class="row py-4">
          <div class="col-lg-3 col-3 text-center">
            <img
              src={require("../image/icons/premium-quality.png")}
              class="brandlf"
              alt=""
              srcset=""
            />
            <p class="mb-0 mobtext">Quality & Savings</p>
          </div>
          <div class="col-lg-3 col-3 text-center">
            <img
              src={require("../image/icons/fast.png")}
              class="brandlf"
              alt=""
              srcset=""
            />
            <p class="mb-0 mobtext">Fast & Convenient</p>
          </div>
          <div class="col-lg-3 col-3 text-center">
            <img
              src={require("../image/icons/ps.png")}
              class="brandlf"
              alt=""
              srcset=""
            />
            <p class="mb-0 mobtext">Payment Security</p>
          </div>
          <div class="col-lg-3 col-3 text-center">
            <img
              src={require("../image/icons/headphones.png")}
              class="brandlf"
              alt=""
              srcset=""
            />
            <p class="mb-0 mobtext">Professional Service</p>
          </div>
        </div>
      </div>

      <Bottomnav />
      <Footer />
    </>
  );
}

export default Home;

import { BrowserRouter as Router, Route } from "react-router-dom";
import UserInfo from "./info"
import DeliveryInfo from "./delivery.js"
import OngoingOrder from "./Onorder.js"
import Order from "./Order.js"
import Addnewaddress from "./addnewaddress";

function Mobile(){
    return(
        <>
        <Route path="/userinfo">
        <UserInfo/>
        </Route>
        <Route path="/deliveryinfo">
            <DeliveryInfo/>
        </Route>
        <Route path="/ongoingorder">
            <OngoingOrder/>
        </Route>
        <Route path="/order">
            <Order/>
        </Route>
        <Route path="/addnewaddress">
            <Addnewaddress/>
        </Route>
        </>
    )
}

export default Mobile;
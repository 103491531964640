import { useEffect, useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Addnewaddress() {

  let history = useHistory();
  const [phone, setPhone] = useState("");
  const [coun, setCoun] = useState(0);
  const [aid, setAid] = useState("");
  const [dlist, setDlist] = useState([]);
  const [hname, setHname] = useState("");
  const [sname, setSname] = useState("");
  const [aname, setAname] = useState("");
  const [street, setStreet] = useState("");
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");


  function delivery() {
    const data = new FormData();
    data.append("name", name);
    data.append("phone", phone);
    data.append("label", label);
    data.append("hname", hname);
    data.append("sname", sname);
    data.append("aname", aname);
    data.append("street", street);
    data.append("token", localStorage.getItem("token"));

    fetch("https://server.itphonestore.com/itphone/deliverypost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setCoun(coun + 1);
        toast.success("Delivery address added !", {
          icon: "🚚",
        });
        history.push("/cart");
      })
      .catch((err) => console.log(err));
  }

  return (
    <div class="modal-content">
      <div class="modal-body">
        <div class="conatiner my-2">
          <p class="fs-4 pb-3">Delivery Address</p>

          <div class=" g-3">
            <div class="row mt-2">
              <div class="col-lg-6 mt-2">
                <label
                  for="inputfullname"
                  class="form-label text-muted"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  id="inputfullname"
                />
              </div>
              <div class="col-lg-6 mt-2">
                <label
                  for="inputnumber"
                  class="form-label text-muted"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  class="form-control"
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }}
                  id="inputnumber"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6">
                <label
                  for="inputhousename"
                  class="form-label text-muted"
                >
                  House Number
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setHname(e.target.value)
                  }}
                  id="inputhousename"
                />
              </div>

              <div class="col-lg-6">
                <label
                  for="inputAreaname"
                  class="form-label text-muted"
                >
                  Area Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setAname(e.target.value)
                  }}
                  id="inputAreaname"
                />
              </div>
            </div>



            <div class="row mt-2">


              <div class="col-lg-6">
                <label
                  for="inputAreaname"
                  class="form-label text-muted"
                >
                  Street
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setSname(e.target.value)
                  }}
                  id="inputAreaname"
                />
              </div>
            </div>



            <div class="row mt-2">
              <div class="col-lg-6">
                <label
                  for="inputStatename"
                  class="form-label text-muted"
                >
                  State
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setStreet(e.target.value)
                  }}
                  id="inputStatename"
                />
              </div>
            </div>
            <div class="row mt-2">
              <label
                for="inputAreaname"
                class="form-label text-muted"
              >
                Label
              </label>
              <div class="col-lg-3">
                <button onClick={() => {
                  setLabel('home')
                }} class="btn btn-light home-office-btn w-75">
                  Home
                </button>
              </div>
              <div class="col-lg-3 ">
                <button onClick={() => {
                  setLabel('office')
                }} class="btn btn-light home-office-btn w-75">
                  Office
                </button>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-6"></div>
              <div class="col-lg-6 text-end">
                <button onClick={delivery} class="btn add-address-btn-modal-save">
                  Add Address
                </button>
                <button class="btn  mx-3 add-address-btn-modal-cancel">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addnewaddress;
import { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bottomnav from "./bottomnav";
import PersonalInfo from "./account/info";
import DeliveryInfo from "./account/delivery";
import OngoingOrder from "./account/Onorder";
import Order from "./account/Order";

function Account() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />
      <div class="container-fluid moboff bg-white">
        <div class="row">
          <div class="col-lg-3 profileleft">
            <div class="container manage-pro-margine">
              <ul
                class="nav  flex-column manage-ul-margine"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <p class="manage-pro-text">Profile</p>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active profile-text-color"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Personal Info
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="delivery-address-tab"
                    data-bs-toggle="tab"
                    href="#delivery-address"
                    role="tab"
                    aria-controls="delivery-address"
                    aria-selected="false"
                  >
                    Address Book
                  </a>
                </li>
                <li class="nav-item mt-4" role="presentation">
                  <p class="manage-pro-text">Your Order</p>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="ongoing-tab"
                    data-bs-toggle="tab"
                    href="#ongoing"
                    role="tab"
                    aria-controls="ongoing"
                    aria-selected="false"
                  >
                    Ongoing Order
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="order-history-tab"
                    data-bs-toggle="tab"
                    href="#order-history"
                    role="tab"
                    aria-controls="order-history"
                    aria-selected="false"
                  >
                    Previous Order
                  </a>
                </li>

                <li class="nav-item mt-4" role="presentation">
                  <p class="manage-pro-text">Help Center</p>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link text-decoration-none  profile-text-color"
                    id="profile-tab"
                    href="/policy"
                  >
                    Return Policy
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link text-decoration-none   profile-text-color"
                    id="delivery-address-tab"
                    href="/contact"
                  >
                    Live Contact
                  </a>
                </li>

                <li class="nav-item mt-2" role="presentation">
                  <Link
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                    to={"/"}
                    class="d-flex flex-row p-2 px-0 align-items-center"
                    style={{
                      textDecoration: "none",
                      color: "#565656",
                      cursor: "pointer",
                    }}
                  >
                    <a class="nav-link profile-text-color">Log Out</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-12 border-start profileright">
            <div
              style={{ overflow: "auto", height: "90vh" }}
              class="container-fluid bg-white profileitem-main position-relative"
            >
              <div class="tab-content " id="myTabContent">
                <div
                  class="tab-pane show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <PersonalInfo />
                </div>

                <div
                  class="tab-pane"
                  id="delivery-address"
                  role="tabpanel"
                  aria-labelledby="delivery-address-tab"
                >
                  <DeliveryInfo />
                </div>
                <div
                  class="tab-pane fade show"
                  id="ongoing"
                  role="tabpanel"
                  aria-labelledby="ongoing-tab"
                >
                  <OngoingOrder />
                </div>
                <div
                  class="tab-pane show"
                  id="order-history"
                  role="tabpanel"
                  aria-labelledby="order-history-tab"
                >
                  <Order />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mobc profilemob">
        <div class="row">
          <p class="my-2 fs-5 mb-1 px-3 pb-0">
            Hello! Welcome to IT Mobile Phones LLC
          </p>
          <div class="col-12 p-3 pt-1">
            <div class="bg-light mb-3">
              <div class="d-flex flex-row border-bottom p-2 align-items-center">
                <img
                  src={require("../image/icons/user-profile.png")}
                  class="webicon pt-0 mt-0"
                />
                <p class="mb-0 mx-2">Profile</p>
              </div>

              <div
                class="d-flex flex-row p-2 py-3 bg-white justify-content-between"
                style={{ borderBottom: "1px dashed #ddd" }}
              >
                <h5 class="mb-0">Personal Info</h5>
                <Link to={"/userinfo"}>
                  <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
                </Link>
              </div>

              <div class="d-flex flex-row p-2 py-3 bg-white justify-content-between">
                <h5 class="mb-0">Address Book</h5>
                <Link to={"/deliveryinfo"}>
                  <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
                </Link>
              </div>
            </div>

            <div class="bg-light mb-3">
              <div class="d-flex flex-row border-bottom p-2 align-items-center">
                <img
                  src={require("../image/icons/orders.png")}
                  class="webicon pt-0 mt-0"
                />
                <p class="mb-0 mx-2">Your Order</p>
              </div>
              <div
                class="d-flex flex-row p-2 py-3 bg-white justify-content-between"
                style={{ borderBottom: "1px dashed #ddd" }}
              >
                <h5 class="mb-0">Ongoing Order</h5>
                <Link to={"/ongoingorder"}>
                  <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
                </Link>
              </div>

              <div class="d-flex flex-row p-2 py-3 bg-white justify-content-between">
                <h5 class="mb-0">Previous Order</h5>
                <Link to={"/order"}>
                  <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
                </Link>
              </div>
            </div>

            <div class="bg-light mb-3">
              <div class="d-flex flex-row border-bottom p-2 align-items-center">
                <img
                  src={require("../image/icons/conversation.png")}
                  class="webicon pt-0 mt-0"
                />
                <p class="mb-0 mx-2">Help Center</p>
              </div>
              <div
                class="d-flex flex-row p-2 py-0 align-items-center bg-white justify-content-between"
                style={{ borderBottom: "1px dashed #ddd" }}
              >
                <a
                  className="nav-link  mb-0 text-decoration-none text-dark"
                  href="/policy"
                >
                  Return Policy
                </a>
                <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
              </div>

              <div class="d-flex flex-row p-2 py-0 align-items-center bg-white justify-content-between">
                <a
                  class="nav-link text-decoration-none mb-0    text-dark"
                  href="/contact"
                >
                  Live Contact
                </a>
                <i class="fa-sharp fa-solid fa-arrow-right text-secondary"></i>
              </div>
            </div>

            <div class="bg-light mb-5">
              <div class="d-flex flex-row border-bottom p-2 align-items-center">
                <p class="mb-0 mx-2">
                  <Link
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                    to={"/"}
                    class="d-flex flex-row p-2 px-0 align-items-center"
                    style={{
                      textDecoration: "none",
                      color: "#565656",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={require("../image/icons/logout.png")}
                      class="webiconsm pt-0 mt-0"
                    />
                    <p class="mb-0 mx-2" style={{ cursor: "pointer" }}>
                      Log Out
                    </p>
                  </Link>
                </p>
              </div>
            </div>

            {/* <div class="container manage-pro-margine">
              <ul
                class="nav  flex-column manage-ul-margine"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <p class="manage-pro-text">প্রোফাইল</p>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active profile-text-color"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    ব্যক্তিগত তথ্য
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="delivery-address-tab"
                    data-bs-toggle="tab"
                    href="#delivery-address"
                    role="tab"
                    aria-controls="delivery-address"
                    aria-selected="false"
                  >
                    ঠিকানা সমূহ
                  </a>
                </li>
                <li class="nav-item mt-5" role="presentation">
                  <p class="manage-pro-text">আমার অর্ডার</p>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="ongoing-tab"
                    data-bs-toggle="tab"
                    href="#ongoing"
                    role="tab"
                    aria-controls="ongoing"
                    aria-selected="false"
                  >
                    চলমান অর্ডার
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link profile-text-color"
                    id="order-history-tab"
                    data-bs-toggle="tab"
                    href="#order-history"
                    role="tab"
                    aria-controls="order-history"
                    aria-selected="false"
                  >
                    পূর্বের অর্ডার
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <Bottomnav />
    </>
  );
}

export default withRouter(Account);

import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Navbarp from "./productnav";
import Sidebar from "./sidebar";
import Bottomnav from "./bottomnav";
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Productnav from "./productnav";
import Productnav2 from "./productnav2";
import Footer from "./footer";
import Select from "react-select";
import { InputSuggestions } from "react-input-suggestions";

var alloption = [];
function WholeSale() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [producttype, setProductType] = useState("");
  const [brand, setBrand] = useState("");
  const [device, setDevice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    { value: "Google", label: "Google" },
    { value: "Motorola", label: "Motorola" },
    { value: "Oppo", label: "Oppo" },
    { value: "Oneplus", label: "Oneplus" },
    { value: "Vivo", label: "Vivo" },
    { value: "Huawei", label: "Huawei" },
    { value: "Nokia", label: "Nokia" },
    { value: "Realme", label: "Realme" },
    { value: "Honor", label: "Honor" },
    { value: "Samsung", label: "Samsung" },
    { value: "Apple", label: "Apple" },
  ];

  function submitWholesale() {
    console.log(alloption);

    const data = new FormData();
    data.append("name", name);
    data.append("phone", phone);
    data.append("email", email);
    data.append("address", address);
    data.append("ptype", producttype);
    data.append("brand", alloption);
    data.append("device", device);
    data.append("qty", quantity);

    fetch("https://server.itphonestore.com/itphone/submitwhole", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message == null) {
          document.getElementById("saleform").reset();
          toast.success("Quote sent");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />
      <div class="container-fluid">
        <div className="row justify-content-center">
          {/* <div className="col-lg-6 col-12 p-5">
            <h3 className="text-dark fw-bold text-center mb-5">
              Frequently Asked Questions
            </h3>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Questions - 1
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">Answer will be here</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Questions - 2
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">Answer will be here</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Questions - 3
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">Answer will be here</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefour"
                    aria-expanded="false"
                    aria-controls="flush-collapsefour"
                  >
                    Questions - 4
                  </button>
                </h2>
                <div
                  id="flush-collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">Answer will be here</div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-6 col-12 p-5 p-lg-5 wholesellbg">
            <div class="mb-4 text-center">
              <h4 className="py-1 fw-bold text-dark">
                Get a Quote For Bulk Purchase
              </h4>
            </div>
            <form id="saleform" class="px-0 px-lg-5 mx-0 mx-lg-5">
              <div class="mb-3">
                <label for="" class="form-label">
                  Name
                </label>
                <input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  placeholder=""
                  aria-describedby="helpId"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Contact
                </label>
                <input
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  placeholder=""
                  aria-describedby="helpId"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  name=""
                  id=""
                  class="form-control"
                  placeholder=""
                  aria-describedby="helpId"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Address
                </label>
                <textarea
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  class="form-control"
                  name=""
                  id=""
                  rows="3"
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Product Type
                </label>
                <select
                  value={producttype}
                  onChange={(e) => {
                    setProductType(e.target.value);
                  }}
                  name=""
                  id=""
                  className="form-control"
                >
                  <option selected disabled>
                    Select..
                  </option>
                  <option>Phone</option>
                  <option>Tab</option>
                  <option>Watches</option>
                  <option>Accessories</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Brand
                </label>
                <Select
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={selectedOption}
                  onChange={(e) => {
                    // setAlloption(oldArr => ([...oldArr, ...e[alloption.length]['value']]));

                    alloption.push(e[alloption.length]["value"]);
                  }}
                  options={options}
                />

                {/* <input
                                    onChange={(e) => {
                                        setSelectedOption(e.target.value);
                                    }}
                                    type="text" name="" id="" class="form-control" placeholder="" aria-describedby="helpId" /> */}
                {/* <InputSuggestions
                                    autoFocus
                                    suggestions={[
                                        'polite',
                                        'fastidious',
                                        'dull',
                                        'pudding',
                                        'mole',
                                        'angle',
                                    ].map(word => (
                                        <span
                                            key={word}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    customFunction(word)
                                                }
                                            }}
                                            onClick={() => {
                                                customFunction(word)
                                            }}
                                        >
                                            {word}
                                        </span>
                                    ))}
                                /> */}
              </div>

              <div class="mb-3">
                <label for="" class="form-label">
                  Device
                </label>
                <input
                  value={device}
                  onChange={(e) => {
                    setDevice(e.target.value);
                  }}
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  placeholder=""
                  aria-describedby="helpId"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label">
                  Quantity
                </label>
                <input
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  placeholder=""
                  aria-describedby="helpId"
                />
              </div>
              <div class="mb-3 pt-3 text-center">
                <button
                  onClick={() => {
                    submitWholesale();
                  }}
                  type="button"
                  class="btn bcolor fw-bold text-light w-50"
                >
                  Request For Quotation
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default WholeSale;

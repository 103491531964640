import { useEffect, useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import Bottomnav from "../bottomnav";
import Navbar from "../navbar";
import Navbar2 from "../navbar2";

function OngoingOrder() {
  let history = useHistory();
  const [coun, setCoun] = useState(0);
  const [activeid, setActiveid] = useState("");
  const [accept, setAccept] = useState("");
  const [status, setStatus] = useState("");
  const [detail, setOdetail] = useState([]);
  const [olist, setOlist] = useState([]);
  const [oitem, setOitem] = useState([]);
  const [allprod, setAllprod] = useState([]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchodetail", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setOdetail(res.message);
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      const data = new FormData();
      data.append("token", localStorage.getItem("token"));
      fetch("https://server.itphonestore.com/itphone/olist", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setOlist(res.message);
          setOitem(res.message2);
        })
        .catch((err) => console.log(err));

      fetch("https://server.itphonestore.com/itphone/fetchproduct", {
        method: "POST",
      })
        .then((res) => res.json())
        .then((res) => {
          setAllprod(res.message);
        })
        .catch((err) => console.log(err));
    }
  }, [coun]);

  function receiveIt() {
    setAccept("");
    const data = new FormData();
    data.append("id", activeid);
    fetch("https://server.itphonestore.com/itphone/receiveIt", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setCoun(coun + 1);
      })
      .catch((err) => console.log(err));
  }
  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
  function formatDate2(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      ", " +
      strTime
    );
  }

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div
        class="modal fade"
        id="timelineon"
        tabindex="-1"
        aria-labelledby="timeline"
        aria-hidden="true"
      >
        <div class="modal-dialog order_track_modal">
          <div class="modal-content order_track_main">
            <div class="order_track_modalh">
              <h5 class="px-3 py-2 mb-0 text-white" id="exampleModalLabel">
                Order Tracking
              </h5>
            </div>
            <div class="modal-body">
              {status.toLocaleLowerCase().includes("delivered") &&
                accept == 0 ? (
                <button
                  onClick={() => receiveIt()}
                  className="btn btn-info m-1"
                  data-bs-dismiss="modal"
                >
                  Accept
                </button>
              ) : null}

              {detail
                .filter((item) => {
                  if (item.pid == activeid) {
                    return item;
                  }
                })
                .map((item) => (
                  <div className="d-flex" style={{ padding: 5 }}>
                    {item.status == "Order Placed" ? (
                      <img
                        src={require("../../image/icons/orderplaced.png")}
                        class="webiconBL pt-0 mt-0"
                      />
                    ) : item.status == "Confirmed" ? (
                      <img
                        src={require("../../image/icons/orderconfirmed.png")}
                        class="webiconBL pt-0 mt-0"
                      />
                    ) : item.status == "Delivered" ? (
                      <img
                        src={require("../../image/icons/ondeliver.png")}
                        class="webiconBL pt-0 mt-0"
                      />
                    ) : item.status == "Received" ? (
                      <img
                        src={require("../../image/icons/orderreceived.png")}
                        class="webiconBL pt-0 mt-0"
                      />
                    ) : null}

                    <div>
                      <p class="mx-2 mb-0">
                        {item.status == "Order Placed"
                          ? "Order Placed"
                          : item.status == "Confirmed"
                            ? "Order Confirmed"
                            : item.status == "Delivered"
                              ? "Order on Deliver"
                              : item.status == "Received"
                                ? "Order Received"
                                : null}
                      </p>
                      <span>
                        <small class="mx-2 text-muted">
                          {formatDate2(item.date)}
                        </small>
                      </span>
                      <br />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row mx-0 my-2 pb-3 border bg-light">
          <p class="fs-5 py-1 border-bottom">Ongoing Order</p>

          {olist
            .filter((item) => {
              if (item.status != "Received") {
                return item;
              }
            })
            .map((item) => (
              <div class="conatiner">
                <div class="row px-0">
                  <div class="col-lg-4 col-8">
                    <p class="fw-bold">
                      Order Number<span>#{item.id}</span>{" "}
                      <span class="px-3">{formatDate(item.date)}</span>
                    </p>
                  </div>
                  <div class="col-lg-5 col-4 text-end">
                    <p class="fw-bold mb-0">AED {item.total}</p>
                  </div>
                  <div class="col-lg-3 order-btn-h d-flex">
                    <button class="btn btn-orderstatus w-75 text-white">
                      {item.status == "Order Placed"
                        ? "Order Placed"
                        : item.status == "Confirmed"
                          ? "Order Confirmed"
                          : item.status == "Delivered"
                            ? "Order on Deliver"
                            : item.status == "Received"
                              ? "Order Received"
                              : null}
                    </button>
                    <div style={{ width: 10 }}></div>
                    <button
                      onClick={() => {
                        setActiveid(item.id);
                        setStatus(item.status);
                        setAccept(item.accept);
                      }}
                      class="btn btn-orderstatus2 w-75 text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#timelineon"
                    >
                      Order Tracking
                    </button>
                  </div>
                </div>

                {oitem.map((x) => (
                  <div class="container-fluid mt-2 px-2">
                    {item.id == x.oid ? (
                      <div class="row orderedplist py-2">
                        <div class="col-lg-2 col-4">
                          {allprod.map((y) => (
                            <>
                              {y.id == x.pid ? (
                                <img
                                  src={
                                    "https://server.itphonestore.com/itphone/image/" +
                                    y.pimage
                                  }
                                  class="order-img"
                                  alt=""
                                  srcset=""
                                />
                              ) : null}
                            </>
                          ))}
                        </div>
                        <div class="col-lg-7 col-8">
                          <p class="cart-pro-name2 m-0">{x.name}</p>

                          <p class="text-warning m-0">AED {x.price}</p>
                          <p class="m-0 cart-pro-name2">Qty: {x.quan}</p>
                        </div>

                        <div class="col-lg-3 col-12 text-end my-auto"></div>
                      </div>
                    ) : null}
                  </div>
                ))}

                <hr />
              </div>
            ))}
        </div>
      </div>

      <Bottomnav />
    </>
  );
}

export default OngoingOrder;

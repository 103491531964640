import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Sidebar from "./sidebar";
import Bottomnav from "./bottomnav";
import { withRouter, Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";

function Categorym() {
  let history = useHistory();
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [product, setProduct] = useState([]);
  const [popular, setPopular] = useState([]);
  const [covers, setCovers] = useState([]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchflash", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setPopular(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/coverfetch", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCovers(res.message))
      .catch((err) => console.log(err));
  }, [covers]);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />

      <div class="container-fluid mt-lg-3 mt-1">
        <div class="row">
          <div class="col-lg-12 mobc mx-0 px-1">
            <div class="row px-lg-5 px-0 pt-2 pb-lg-4 pb-2 mx-0">
              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/1"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-6 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/maldress.jpg")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-6 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">ছেলেদের কাপড়</h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/2"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/fdress.jpg")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">মেয়েদের কাপড়</h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/3"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/sunglass.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">ফ্যাশন ও জুয়েলারী</h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/4"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/hb.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">
                        স্বাস্থ্য ও সৌন্দর্য
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/5"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/sport.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">স্পোর্টস</h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/6"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/babyc.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">বেবি কেয়ার</h5>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/7"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/Bag.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">ব্যাগ</h5>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/9"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-8 text-center">
                      <p class="mb-lg-0 mb-2 navcname">Watchs</p>
                    </div>
                    <div class="col-lg-4 pb-lg-0 pb-2 text-center">
                      <img
                        src={require("../image/icons/Watch.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </Link>
              </div> */}

              <div class="col-lg-4 col-6 my-lg-3 my-2">
                <Link to={"/category/8"} class="card cardcm navlinkc">
                  <div class="row py-lg-3 py-1">
                    <div class="col-lg-4 pb-lg-0 py-2 text-center">
                      <img
                        src={require("../image/icons/earg.png")}
                        class="nav-logo-4"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="col-lg-8 text-center">
                      <h5 class="mb-lg-0 mb-2 navcname">
                        ইলেকট্রনিক্স গ্যাজেট
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottomnav />
    </>
  );
}

export default Categorym;

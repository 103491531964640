import logo from "../image/icons/logo.png";
import facebook from "../image/icons/facebook.png";
import youtube from "../image/icons/youtube.png";
import twitter from "../image/icons/twitter.png";
import tiktok from "../image/icons/tiktok.png";
import instagram from "../image/icons/instagram.png";
import linkedin from "../image/icons/linkedin.png";
import { withRouter, Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import { useEffect, useState } from "react";
import Whatsappicon from "../image/whatsapp.png"
function Footer() {
  const [category, setCategory] = useState([]);
  const [brandList, setBrandList] = useState([]);

  let country = "+971"

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandList(res.message);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div class="container-fluid footer moboff">
        <div class="row py-5">
          <div class="col-lg-8 rounded-0 border-0 text-center ">
            <div class="row mx-3">
              {category.map((cate) => (
                <div class="col-lg-4">
                  <p>
                    <Link
                      className="navlinkcf"
                      to={"/category/" + cate.cname + "/all"}
                    >
                      {cate.cname}
                    </Link>
                  </p>

                  {brandList.map((item) => {
                    if (item.brandCate == cate.cname) {
                      return (
                        <>
                          <small style={{ fontSize: 12 }}>
                            <Link
                              className="navlinkcf"
                              to={
                                "/category/" + cate.cname + "/" + item.brandName
                              }
                            >
                              {item.brandName}
                            </Link>
                          </small>
                          <br></br>
                        </>
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          </div>
          <div class="col-lg-4 col-md-4 text-white">
            <h5>Contact Us</h5>
            <small class="mb-0">Iqbal & Tareq Mobile Phones LLC.</small>
            <br></br>
            <small style={{ fontSize: 12 }}>

              <p className="fs-6"><span class="fa fa-map-marker"></span>&nbsp;&nbsp; P.O.Box: 172201 Al Musallah Road Deira Dubai - U.A.E.</p>
              <p className="fs-6"><span class="fa fa-phone"></span>&nbsp;&nbsp;
                <b>00971554083760, 00971508781204, 00971552346493</b></p>
              <p className="fs-6"><span class="fa fa-envelope"></span>&nbsp;&nbsp;itphones846@hotmail.com</p>

            </small>

            <div class="">
              <a href="">
                <img src={facebook} class="footicon" />
              </a>
              <a href="">
                <img src={instagram} class="footicon" />
              </a>
              <a href="">
                <img src={twitter} class="footicon" />
              </a>
              <a href="">
                <img src={linkedin} class="footicon" />
              </a>
            </div>
          </div>
        </div>
        <div class="container-fluid footer text-center pb-2">
          <div class="col-lg-12 text-white" style={{ fontSize: 12 }}>
            <p class="mb-0">
              © Iqbal & Tareq Mobile Phones LLC, All Rights Reserved
            </p>
            <p class="">
              Developed by
              <a
                href="https://mpairtech.com"
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                {" "}
                mPair Technologies Ltd.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid footer mobc pb-5">
        <div class="row py-5">
          <div class="col-lg-8 rounded-0 border-0 pb-3">
            <div class="row mx-3">
              {category.map((cate) => (
                <div class="col-lg-4 col-4">
                  <p>
                    <Link
                      className="navlinkcf"
                      to={"/category/" + cate.cname + "/all"}
                    >
                      {cate.cname}
                    </Link>
                  </p>

                  {brandList.map((item) => {
                    if (item.brandCate == cate.cname) {
                      return (

                        <>
                          <small style={{ fontSize: 12 }}>
                            <Link
                              className="navlinkcf"
                              to={
                                "/category/" + cate.cname + "/" + item.brandName
                              }
                            >
                              {item.brandName}
                            </Link>


                          </small>

                          <br></br>
                        </>
                      );
                    }
                  })}


                </div>
              ))}



            </div>

          </div>
          <hr />
          <div class="col-lg-4 col-12 text-center text-white">
            <h5>Contact Us</h5>
            <small class="mb-0">Iqbal & Tareq Mobile Phones LLC.</small>
            <br></br>
            <small style={{ fontSize: 12 }}>UAE</small>

            <div class="mt-2">
              <a href="">
                <img src={facebook} class="footicon" />
              </a>
              <a href="">
                <img src={instagram} class="footicon" />
              </a>
              <a href="">
                <img src={twitter} class="footicon" />
              </a>
              <a href="">
                <img src={linkedin} class="footicon" />
              </a>
            </div>
            <Link to={"/policy"} className="text-decoration-none">
              <small class="navlinkcf">Policy</small>
            </Link>&nbsp;&nbsp;
            <Link to={"/terms"} className="text-decoration-none">
              <small class="navlinkcf ">Terms and Conditions</small>
            </Link>
          </div>
        </div>
        <div class="container-fluid footer text-center pb-2">
          <div class="col-lg-12 text-white" style={{ fontSize: 10 }}>
            <p class="mb-0" style={{ fontSize: 10 }}>
              © Iqbal & Tareq Mobile Phones LLC, All Rights Reserved
            </p>
            <p class="" style={{ fontSize: 10 }}>
              Developed by
              <a
                href="https://mpairtech.com"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  fontSize: 10,
                }}
              >

                {" "}
                mPair Technologies Ltd.
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class=" chatlocation">
        <ReactWhatsapp className="btn " number={country + '556073659'} message={''}>
          {/* <i class="fa-brands fa-square-whatsapp text-success p-0 m-0" ></i> */}
          <img src={Whatsappicon} style={{ height: "50px", width: "50px", }} />
        </ReactWhatsapp>
      </div>

    </>
  );
}

export default Footer;

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Sidebar from "./sidebar2";
import { withRouter } from "react-router-dom";
import Navbar from "./navbar";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactImageMagnify from "react-image-magnify";
import Bottomnav from "./bottomnav";
import Productnav from "./productnav";
import Footer from "./footer";

function Product(props) {
  let history = useHistory();
  var i = props.match.params.id;

  const [product, setProduct] = useState([]);
  const [products, setProducts] = useState([]);

  const [html, setHTML] = useState({ __html: "" });

  const [star, setStar] = useState(1);
  const [comment, setComment] = useState("");
  const [review, setReview] = useState([]);
  const [coun, setCoun] = useState(0);
  const [alluser, setAlluser] = useState([]);
  const [show, setShow] = useState(false);
  const [st, setSt] = useState("");
  const [pquan, setPquan] = useState(1);
  const [cate, setCate] = useState("");
  const [variant, setVariant] = useState([]);
  const [acolor, setAcolor] = useState("");
  const [asize, setAsize] = useState("");
  const [activeimage, setActiveimage] = useState("");
  const [wlist, setWlist] = useState([]);
  const [wlistm, setWlistm] = useState([]);

  const [hassize, setHassize] = useState(false);
  const [hascolor, setHascolor] = useState(false);

  const [ratings, setRatings] = useState("");

  const [allcate, setAllcate] = useState([]);
  const [desImage, setDesimage] = useState([]);

  useEffect(() => {
    const data = new FormData();
    data.append("cate", cate);
    fetch("https://server.itphonestore.com/itphone/fetchcate2", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setWlist(res.message);
      })
      .catch((err) => console.log(err));
  }, [wlist]);

  useEffect(() => {
    const data = new FormData();
    data.append("cate", cate);
    fetch("https://server.itphonestore.com/itphone/fetchcaten", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setWlistm(res.message);
      })
      .catch((err) => console.log(err));
  }, [wlistm]);

  useEffect(() => {
    const data = new FormData();
    data.append("prodId", i);
    fetch("https://server.itphonestore.com/itphone/getDesImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setDesimage(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function stockCount() {
    var total = 0;
    variant.map((item) => {
      if (
        item.color.toLocaleLowerCase().includes(acolor.toLocaleLowerCase()) &&
        item.size.toLocaleLowerCase().includes(asize.toLocaleLowerCase())
      ) {
        total = total + item.stock;
      }
    });
    return total;
  }
  function postreview() {
    const data = new FormData();
    data.append("star", star);
    data.append("comment", comment);
    data.append("pid", i);
    data.append("cid", localStorage.getItem("token"));

    fetch("https://server.itphonestore.com/itphone/postreview", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
        setComment("");
        toast.success("Review added");

        setCoun(coun + 1);
      })
      .catch((err) => console.log(err));
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      strTime
    );
  }

  useEffect(() => {
    var data = new FormData();
    data.append("pid", i);

    fetch("https://server.itphonestore.com/itphone/fetchvariant", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setVariant(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      setShow(false);
    }

    if (localStorage.getItem("token") != null) {
      var data = new FormData();
      data.append("token", localStorage.getItem("token"));
      data.append("pid", i);

      fetch("https://server.itphonestore.com/itphone/checkcom", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          setShow(res.message);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    var data = new FormData();
    data.append("id", i);

    fetch("https://server.itphonestore.com/itphone/fetchsproduct", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
        setHassize(res.hasSize);
        setHascolor(res.hasColor);

        setActiveimage(res.message[0].pimage);
        setProduct(res.message);
        setCate(res.message[0].category);
        setRatings(res.message[0].star);

        const backendHtmlString = res.message[0].des;
        setHTML({ __html: backendHtmlString });
      })
      .catch((err) => console.log(err));
  }, [i, coun]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setAllcate(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = new FormData();
    data.append("id", i);

    fetch("https://server.itphonestore.com/itphone/fetchreview", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
        setReview(res.message);
        setSt(res.star);
      })
      .catch((err) => console.log(err));
  }, [coun]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchuser", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setAlluser(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function starget(x) {
    var y = ["a", "b", "c"];

    {
      y.map((item) => <i class="fa-solid fa-star fa-2xs text-warning"></i>);
    }
  }

  const dispatch = useDispatch();
  return (
    <>
      <Navbar />
      <ToastContainer />
      <Productnav />

      <div
        class="modal fade animate__animated animate__slideInUp "
        id="addcartmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog addcartModal">
          <div class="modal-content addcartModalC">
            {product.map((item) => (
              <>
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add Product to Cart
                  </h5>
                  <button
                    type="button"
                    class="btn btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="modal-body ">
                  {hascolor ? (
                    <div class="border-bottom">
                      <p class="mb-2">Select Color</p>
                      <div class="mb-2" style={{ display: "flex" }}>
                        {variant.map((item) => (
                          <div
                            onClick={() => {
                              setAcolor(item.color);
                            }}
                            style={{
                              backgroundColor: item.color,
                              width: 25,
                              height: 25,
                              margin: 4,
                              border: "2px solid #ddd",
                              cursor: "pointer",
                              borderRadius: 50,
                            }}
                          >
                            {item.color == acolor ? (
                              <i
                                class="fa fa-check m-1"
                                style={{ color: "white" }}
                              ></i>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  <div class="py-2 border-bottom">
                    <p class="mb-0">
                      Stock: <span> {stockCount()}</span>
                    </p>
                  </div>
                  <div class="row mt-3 mb-3 align-items-center">
                    <div class="col-lg-2">
                      <p class="m-0">Qty</p>
                    </div>

                    <div class="col-lg-6">
                      <button
                        class="btn bg-light btn-circle"
                        data-field="quantity"
                      >
                        <i
                          class="fa fa-minus"
                          style={{ fontSize: 13 }}
                          onClick={() => {
                            if (pquan < 2) {
                              setPquan(1);
                            } else {
                              setPquan(pquan - 1);
                            }
                          }}
                        ></i>
                      </button>
                      <input
                        type="number"
                        placeholder=""
                        name="quantity"
                        disabled
                        class="quantity-field text-center "
                        value={pquan}
                        style={{ width: "3rem", height: "2rem" }}
                      />
                      <button
                        class="btn bg-light btn-circle"
                        data-field="quantity"
                      >
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: 13 }}
                          onClick={() => {
                            if (pquan > Number(stockCount()) - 1) {
                              setPquan(Number(stockCount()));
                            } else {
                              setPquan(pquan + 1);
                            }
                          }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="">
                  <button
                    disabled={stockCount() == 0 ? true : false}
                    onClick={() => {
                      if (hascolor && acolor == "") {
                        toast.warn("Select Color");
                        return false;
                      }

                      dispatch(
                        increment(
                          item.pname,
                          pquan,
                          item.price,
                          asize,
                          acolor,
                          item.id
                        )
                      );

                      toast.success("Product Added to Cart");
                    }}
                    type="button"
                    class="btn p-3 text-white rounded-0 addCartB"
                  >
                    Add to Cart
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div class=" container-fluid bg-white py-lg-3 my-lg-0 my-1">
        <div class="addcrt_btn mobc">
          <button
            class="btn btn_abs text-white"
            data-bs-toggle="modal"
            data-bs-target="#addcartmodal"
          >
            <img
              src={require("../image/icons/cartbag.png")}
              class="navbicon pt-0 mt-0"
            />
            <p class="mb-0" style={{ marginLeft: 12, marginRight: 12 }}>
              Buy
            </p>
          </button>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-10 col-12 px-auto product_body mb-lg-0 mb-2">
            {product.map((item) => (
              <div>
                <div class="bg-white product-container py-3 moboff" id="top">
                  <div class="d-flex flex-row px-3">
                    <div class="productImage">
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            height: 400,
                            width: 400,
                            sizes: "cover",
                            alt: "Wristwatch by Ted Baker London",
                            src:
                              "https://itphonestore.com/image/" + activeimage,
                          },
                          largeImage: {
                            src:
                              "https://itphonestore.com/image/" + activeimage,
                            width: 800,
                            height: 800,
                          },
                        }}
                      />

                      <br />
                      <img
                        class="m-2"
                        onClick={() => setActiveimage(item.pimage)}
                        style={{ width: 80, cursor: "pointer" }}
                        src={"https://itphonestore.com/image/" + item.pimage}
                      />
                      {item.pimage2 != "" ? (
                        <img
                          class="m-2"
                          onClick={() => setActiveimage(item.pimage2)}
                          style={{ width: 80, cursor: "pointer" }}
                          src={"https://itphonestore.com/image/" + item.pimage2}
                        />
                      ) : null}
                      {item.pimage3 != "" ? (
                        <img
                          class="m-2"
                          onClick={() => setActiveimage(item.pimage3)}
                          style={{ width: 80, cursor: "pointer" }}
                          src={"https://itphonestore.com/image/" + item.pimage3}
                        />
                      ) : null}
                    </div>

                    <div class="flex-fill px-4">
                      <p class="fs-3" style={{ fontWeight: 500 }}>
                        {item.pname}
                      </p>

                      <h3 class="pt-3 ">
                        {item.offer == 1 ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: 8,
                                color: "#565656",
                              }}
                            >
                              AED {item.price}
                            </span>
                            <span style={{ marginRight: 8, color: "#ffa200" }}>
                              AED {item.oprice}
                            </span>
                          </>
                        ) : (
                          <span style={{ color: "#ffa200" }}>
                            AED {item.price}
                          </span>
                        )}
                      </h3>

                      <div class="rounded pb-card w-50 p-2">
                        <small class="mtop m-0">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>
                          <span class="mx-1">Ratings: {ratings}</span>

                          <span>
                            <i class="fa-solid fa-star text-warning"></i>
                          </span>
                        </small>
                        <br></br>
                        <small class="mtop m-0">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>
                          <span class="mx-1"> Brand: {item.brand}</span>
                        </small>
                        <br></br>
                        <small class="mtop m-0">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>
                          <span class="mx-1">
                            {" "}
                            Stock:{" "}
                            <span> {product.map((item) => item.quan)}</span>
                          </span>
                        </small>{" "}
                      </div>
                      <div class="my-3 d-flex align-items-center pb-3 border-bottom">
                        <small class="m-0 exd py-1">
                          <img
                            src={require("../image/icons/shield.png")}
                            class="webicons pt-0 mt-0"
                          />
                          <span class="mb-0 mx-1">
                            IT Phones Express Delivery
                          </span>
                        </small>
                      </div>

                      {hascolor ? (
                        <div class="border-bottom">
                          <p class="mb-1">Select Color</p>
                          <div class="mb-2" style={{ display: "flex" }}>
                            {variant.map((item) => (
                              <div
                                onClick={() => {
                                  setAcolor(item.color);
                                }}
                                style={{
                                  backgroundColor: item.color,
                                  width: 25,
                                  height: 25,
                                  margin: 4,
                                  border: "2px solid #ddd",
                                  cursor: "pointer",
                                  borderRadius: 50,
                                }}
                              >
                                {item.color == acolor ? (
                                  <div
                                    style={{
                                      width: 25,
                                      height: 25,
                                      margin: -2,
                                      border: "3px solid #001830",
                                      cursor: "pointer",
                                      borderRadius: 50,
                                    }}
                                  ></div>
                                ) : // <i
                                //   class="fa fa-check px-1"
                                //   style={{ color: "white" }}
                                // ></i>

                                null}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}

                      <div class="row mt-3 mb-3 align-items-center">
                        <div class="col-lg-2">
                          <p class="m-0">Qty</p>
                        </div>

                        <div class="col-lg-4">
                          <button
                            class="btn bg-light btn-circle"
                            data-field="quantity"
                          >
                            <i
                              class="fa fa-minus"
                              style={{ fontSize: 13 }}
                              onClick={() => {
                                if (pquan < 2) {
                                  setPquan(1);
                                } else {
                                  setPquan(pquan - 1);
                                }
                              }}
                            ></i>
                          </button>
                          <input
                            type="number"
                            placeholder=""
                            name="quantity"
                            disabled
                            class="quantity-field text-center "
                            value={pquan}
                            style={{ width: "3rem", height: "2rem" }}
                          />
                          <button
                            class="btn bg-light btn-circle"
                            data-field="quantity"
                          >
                            <i
                              class="fa fa-plus"
                              style={{ fontSize: 13 }}
                              onClick={() => {
                                if (pquan > Number(stockCount()) - 1) {
                                  setPquan(Number(stockCount()));
                                } else {
                                  setPquan(pquan + 1);
                                }
                              }}
                            ></i>
                          </button>
                        </div>
                        <div class="col-lg-6">
                          <button
                            disabled={stockCount() == 0 ? true : false}
                            onClick={() => {
                              if (hascolor && acolor == "") {
                                toast.warn("Select Color");
                                return false;
                              }

                              dispatch(
                                increment(
                                  item.pname,
                                  pquan,
                                  item.price,
                                  asize,
                                  acolor,
                                  item.id
                                )
                              );

                              toast.success("Product Added to Cart");
                            }}
                            class="btn btn-lg w-100 add-cart-btn-success "
                          >
                            <img
                              src={require("../image/icons/cartbag.png")}
                              class="navbicon pt-0 mt-0 mx-2"
                            />
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bg-white product-container rounded-0 py-3 mobc">
                  <div class="col-12 px-3">
                    <div class="">
                      <img
                        style={{ width: "100%" }}
                        src={"https://itphonestore.com/image/" + activeimage}
                      />

                      <br />
                      <img
                        class="m-2"
                        onClick={() => setActiveimage(item.pimage)}
                        style={{ width: 80, cursor: "pointer" }}
                        src={"https://itphonestore.com/image/" + item.pimage}
                      />
                      {item.pimage2 != "" ? (
                        <img
                          class="m-2"
                          onClick={() => setActiveimage(item.pimage2)}
                          style={{ width: 80, cursor: "pointer" }}
                          src={"https://itphonestore.com/image/" + item.pimage2}
                        />
                      ) : null}
                      {item.pimage3 != "" ? (
                        <img
                          class="m-2"
                          onClick={() => setActiveimage(item.pimage3)}
                          style={{ width: 80, cursor: "pointer" }}
                          src={"https://itphonestore.com/image/" + item.pimage3}
                        />
                      ) : null}
                    </div>
                    <div class="col-12 mt-2">
                      <h3 class="mt-3">
                        {item.offer == 1 ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: 8,
                                color: "#565656",
                              }}
                            >
                              AED {item.price}
                            </span>
                            <span style={{ marginRight: 8, color: "#ffa200" }}>
                              AED {item.oprice}
                            </span>
                          </>
                        ) : (
                          <span style={{ color: "#ffa200" }}>
                            AED {item.price}
                          </span>
                        )}
                      </h3>
                      <p class="fs-3" style={{ fontWeight: 500 }}>
                        {item.pname}
                      </p>
                      <div class="card p-1 pb-card">
                        <p class="mtop border-bottom p-2 m-0">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>{" "}
                          Ratings: {ratings}
                          <span>
                            <i class="fa-solid fa-star text-warning"></i>
                          </span>
                        </p>
                        <p class="mtop border-bottom p-2 m-0">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>{" "}
                          Brand: {item.brand}
                        </p>

                        <p class="m-0 p-2">
                          <i class="fa-solid fa-circle-check iconcolorg"></i>{" "}
                          Stock:{" "}
                          <span> {product.map((item) => item.quan)}</span>
                        </p>
                      </div>

                      <div class="my-3 d-flex align-items-center pb-3 border-bottom">
                        <small class="m-0 exd py-1">
                          <img
                            src={require("../image/icons/shield.png")}
                            class="webicons pt-0 mt-0"
                          />
                          <span class="mb-0 mx-1">
                            IT Phones Express Delivery
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="my-lg-3 my-1 bg-white product-container rounded-0">
                  <div
                    class="nav nav-pills product_info_nav border-top"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-link active pr_in_nav_active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <span class="mobfont1">Specification</span>
                    </a>
                    <a
                      class="nav-link pr_in_nav_active"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      <span class="mobfont1">Reviews</span>
                    </a>
                  </div>

                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div class="my-4">
                        <div
                          class=" mx-4 pb-4"
                          dangerouslySetInnerHTML={html}
                        ></div>

                        {desImage.map((item) => (
                          <img
                            src={
                              "https://itphonestore.com/image/" + item.desImage
                            }
                            class="desimgn"
                          />
                        ))}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="my-4">
                        {review.length == 0 ? (
                          <h5 class="text-center text-muted py-3 border-bottom">
                            <i class="fa-regular fa-comment mx-2"></i>No
                            Comments
                          </h5>
                        ) : null}

                        {review
                          .filter((item) => {
                            if (item.comment == "") {
                              return true;
                            } else {
                              return item;
                            }
                          })
                          .map((item) => (
                            <div class="my-2">
                              <div class="row review-container-comment mx-3 border-bottom">
                                <div class="col-lg-2 col-12">
                                  {alluser.map((y) => (
                                    <p class="fw-bold text-muted mb-lg-2 mb-0">
                                      {y.id == item.cid ? y.fname : null}
                                    </p>
                                  ))}
                                </div>
                                <div class="col-lg-8 col-12">
                                  {item.star == 5
                                    ? "⭐ ⭐ ⭐ ⭐ ⭐"
                                    : item.star == 3
                                    ? "⭐ ⭐ ⭐ "
                                    : item.star == 1
                                    ? "⭐"
                                    : null}
                                  <p class="mb-0 mb-lg-2">{item.comment}</p>
                                </div>
                                <div class="col-lg-2 text-end moboff">
                                  <p>{formatDate(item.date)}</p>
                                </div>
                                <div class="col-lg-2 col-12 mobc">
                                  <small class="mb-1 text-muted">
                                    {formatDate(item.date)}
                                  </small>
                                </div>
                              </div>
                            </div>
                          ))}

                        {show ? (
                          <div class="row mx-lg-4 mx-3 review-container-comment pt-2">
                            <div class="col-lg-3 col-12 mb-lg-0 mb-2">
                              Add Your Comments
                            </div>
                            <div class="col-lg-6 card product-container-1">
                              <div class="card-header row">
                                <div class="col-lg-3 col-7 my-lg-0 my-1">
                                  <button
                                    onClick={() => setStar(1)}
                                    class={
                                      star == 1
                                        ? "btn review-btn w-100 d-flex sborder"
                                        : "btn review-btn w-100 d-flex"
                                    }
                                  >
                                    Poor
                                    <div class="d-flex mx-2 star-top">
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                    </div>
                                  </button>
                                </div>
                                <div class="col-lg-4 col-7 my-lg-0 my-1">
                                  <button
                                    onClick={() => setStar(3)}
                                    class={
                                      star == 3
                                        ? "btn review-btn w-100 d-flex sborder"
                                        : "btn review-btn w-100 d-flex"
                                    }
                                  >
                                    Good
                                    <div class="d-flex mx-2 star-top">
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                    </div>
                                  </button>
                                </div>
                                <div class="col-lg-5 col-7 my-lg-0 my-1">
                                  <button
                                    onClick={() => setStar(5)}
                                    class={
                                      star == 5
                                        ? "btn review-btn w-100 d-flex sborder"
                                        : "btn review-btn w-100 d-flex"
                                    }
                                  >
                                    Excelent
                                    <div class="d-flex mx-2 star-top">
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                      <i class="fa-solid fa-star fa-2xs text-warning"></i>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              <div class="card-body ">
                                <div class="form-group">
                                  <textarea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    class="form-control review-textarea-border mt-2"
                                    id="review-textarea"
                                    rows="1"
                                    placeholder="এখানে লিখুন . . ."
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-12">
                              <button
                                class="btn review-success-btn w-100"
                                onClick={postreview}
                              >
                                Add Comment
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="container-fluid bg-white moboff">
        <div class="row justify-content-center">
          <div className="col-lg-10 col-12 bg-white mb-lg-0 mb-2">
            <div class="suggesteditem rounded-0">
              <h5 class="p-lg-3 px-0 py-3 pb-2 border-bottom">
                Recommended Products For You
              </h5>
              <div class="d-flex">
                {wlist.map((item) => (
                  <div to={"/product/2"} class="hpcart3 py-2 px-2">
                    <div class="card pb-3 productcard3 ">
                      <Link class="link" to={"/product/" + item.id + "#top"}>
                        <img
                          src={"https://itphonestore.com/image/" + item.pimage}
                          class="card-img-top3 p-2"
                          alt=""
                          srcset=""
                        />
                        <p class="mtop prname mt-lg-3 mt-1 mb-0 px-2">
                          {item.pname.substring(0, 38)}
                          {item.pname.length > 38 ? "..." : ""}
                        </p>

                        <p class="pprprice2 px-2">
                          {item.offer == 1 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: 8,
                                }}
                              >
                                AED {item.price}
                              </span>
                              <span>AED {item.oprice}</span>
                            </>
                          ) : (
                            <span>AED {item.price}</span>
                          )}
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-white mobc">
        <div class="row justify-content-center">
          <div className="col-lg-10 col-12 bg-white mb-lg-0 mb-2">
            <div class="suggesteditem rounded-0">
              <h5 class="p-lg-3 px-0 py-3 pb-2 border-bottom">
                Recommended Products For You
              </h5>
              <div class="row">
                {wlistm.map((item) => (
                  <div to={"/product/2"} class="col-6 py-2 px-2">
                    <div class="card pb-3 productcard3 ">
                      <Link class="link" to={"/product/" + item.id}>
                        <img
                          src={"https://itphonestore.com/image/" + item.pimage}
                          class="card-img-top3 p-2"
                          alt=""
                          srcset=""
                        />
                        <p class="mtop prname mt-lg-3 mt-1 mb-0 px-2">
                          {item.pname.substring(0, 38)}
                          {item.pname.length > 38 ? "..." : ""}
                        </p>

                        <p class="pprprice2 px-2">
                          {item.offer == 1 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: 8,
                                }}
                              >
                                AED {item.price}
                              </span>
                              <span>AED {item.oprice}</span>
                            </>
                          ) : (
                            <span>AED {item.price}</span>
                          )}
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottomnav />
      <Footer />
    </>
  );
}

export default withRouter(Product);

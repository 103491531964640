import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Navbarp from "./productnav";
import Sidebar from "./sidebar";
import Bottomnav from "./bottomnav";
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Footer from "./footer";

function Brandpage(props) {
  let history = useHistory();
  const cate = " ";
  const brandName = props.match.params.brandName;
  var active = window.location.pathname.replace("/category/", "");

  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [plist, setPlist] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [hrange, setHrange] = useState("");
  const [erange, setErange] = useState("");
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        const unique = [
          ...new Map(res.message.map((m) => [m.brandName, m])).values(),
        ];
        setBrandList(unique);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setPlist(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />

      <div class="container-fluid my-lg-3 my-2 mb-5 pb-3">
        <div class="row">
          <div
            class="d-flex flex-row align-items-center"
            style={{ overflow: "auto" }}
          >
            {brandList.map((item) => (
              <Link
                to={"/brands/" + item.brandName}
                class="branditem text-center py-3 mx-lg-5 mx-3"
              >
                <img
                  src={"https://itphonestore.com/image/" + item.brandImage}
                  class="brandls"
                  alt=""
                  srcset=""
                />
              </Link>
            ))}
          </div>
          <hr></hr>

          <div class="col-lg-12 col-12">
            <div class="row col-lg-12 m-0 px-lg-3 px-0 py-lg-2 py-0">
              <p class="cat-text-m my-2 moboff bangla_font"></p>

              {plist.length == 0 ? <p>No Product Available</p> : null}

              {plist
                .filter((item) => {
                  if (brandName == "all") {
                    return item;
                  } else {
                    if (
                      item.brand.toLowerCase().includes(brandName.toLowerCase())
                    ) {
                      return item;
                    }
                  }
                })
                .map((item) => (
                  <div class="hpcart py-lg-2 py-1 px-lg-2 px-1">
                    <div class="card pb-3 productcard px-lg-4 px-2 pt-3">
                      <Link class="link" to={"/product/" + item.id}>
                        <img
                          src={"https://itphonestore.com/image/" + item.pimage}
                          class="card-img-top px-2"
                          alt=""
                          srcset=""
                        />
                        <p class="mtop prname mt-3 mb-lg-2 mb-0 ">
                          {item.pname}
                        </p>

                        <p class="text-price fs-5 mb-lg-2 mb-0">
                          {item.offer == 1 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: 8,
                                  color: "#565656",
                                  fontWeight: 300,
                                  fontSize: 15,
                                }}
                              >
                                AED {item.price}
                              </span>
                              <span>AED {item.oprice}</span>
                            </>
                          ) : (
                            <span>AED {item.price}</span>
                          )}
                        </p>
                        <p class="m-0 fs-6 text-secondary">
                          <span>
                            <i class="fa-solid fa-star text-warning"></i>
                          </span>
                          <span class="mx-1">{item.star.toFixed(2)}</span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Bottomnav />
      <Footer />
    </>
  );
}

export default withRouter(Brandpage);

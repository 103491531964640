import { useSelector, useDispatch } from "react-redux";
import increment from "../actions";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import Navbarp from "./productnav";
import Sidebar from "./sidebar";
import Bottomnav from "./bottomnav";
import { useHistory } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Productnav from "./productnav";
import Productnav2 from "./productnav2";
import Footer from "./footer";

function Newrelease() {
  let history = useHistory();

  var active = window.location.pathname.replace("/category/", "");

  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [category, setCategory] = useState([]);
  const [plist, setPlist] = useState([]);
  const [hrange, setHrange] = useState("");
  const [erange, setErange] = useState("");
  const [activeBrand, setActiveBrand] = useState("");

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCategory(res.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setPlist(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <ToastContainer />
      <Productnav2 />

      <div class="container-fluid my-lg-3 my-2 mb-5 pb-3">
        <div class="mt-lg-3 mt-1">
          <img
            src={require("../image/icons/nr.png")}
            class="promo2"
            alt=""
            srcset=""
          />
        </div>
        <div class="row">
          <div class="col-lg-9 catpr col-12">
            <div class="row col-lg-12 m-0 px-lg-3 px-0 py-lg-2 py-0">
              <p class="cat-text-m my-2 moboff bangla_font"></p>

              {plist.length == 0 ? <p>No Product Available</p> : null}

              {plist
                .filter((item) => {
                  if (item.newrelease == 1) {
                    return item;
                  }
                })
                .filter((item) => {
                  if (hrange == "") {
                    return item;
                  } else {
                    if (item.offer == 1) {
                      if (item.oprice > hrange && item.oprice < erange) {
                        return item;
                      }
                    } else {
                      if (item.price > hrange && item.price < erange) {
                        return item;
                      }
                    }
                  }
                })
                .filter((item) => {
                  if (
                    item.brand
                      .toString()
                      .toLocaleLowerCase()
                      .includes(activeBrand.toString().toLocaleLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item) => (
                  <div class="hpcart py-lg-2 py-1 px-lg-2 px-1">
                    <div class="card pb-3 productcard px-lg-4 px-3 pt-3">
                      <Link class="link" to={"/product/" + item.id}>
                        <img
                          src={"https://itphonestore.com/image/" + item.pimage}
                          class="card-img-top px-2"
                          alt=""
                          srcset=""
                        />
                        <p class="mtop prname mt-3 mb-lg-2 mb-0 ">
                          {item.pname}
                        </p>

                        <p class="text-price fs-5 mb-lg-2 mb-0">
                          {item.offer == 1 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: 8,
                                  color: "#565656",
                                  fontWeight: 300,
                                  fontSize: 15,
                                }}
                              >
                                AED {item.price}
                              </span>
                              <span>AED {item.oprice}</span>
                            </>
                          ) : (
                            <span>AED {item.price}</span>
                          )}
                        </p>
                        <p class="m-0 fs-6 text-secondary">
                          <span>
                            <i class="fa-solid fa-star text-warning"></i>
                          </span>
                          <span class="mx-1">{item.star.toFixed(2)}</span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Newrelease);

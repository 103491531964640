import { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";

function Search() {
  const [product, setProduct] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div class="searchboxm p-2">
      <div class="bg-white py-1">
        <input
          class="form-control form-control-search py-2 home-search"
          style={{ fontSize: 12 }}
          type="search"
          placeholder="Search Product . . ."
          onChange={(e) => {
            setSearch(e.target.value);
            setShow(true);
          }}
        />
        {show ? (
          <>
            <div class="sbox moboff">
              <div class="list-group">
                {product
                  .filter((item) => {
                    if (
                      item.pname
                        ?.toLocaleLowerCase()
                        .includes(search?.toLocaleLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <Link
                      onClick={() => {
                        setShow(!show);
                      }}
                      to={"/product/" + item.id}
                      class="list-group-item list-group-item-action"
                    >
                      {item.pname}
                    </Link>
                  ))}
              </div>
            </div>
            <div class="sboxm mobc">
              <div class="list-group">
                {product
                  .filter((item) => {
                    if (
                      item.pname
                        ?.toLocaleLowerCase()
                        .includes(search?.toLocaleLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <Link
                      onClick={() => {
                        setShow(!show);
                      }}
                      to={"/product/" + item.id}
                      class="list-group-item list-group-item-action"
                    >
                      {item.pname}
                    </Link>
                  ))}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Search;

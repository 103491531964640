import { useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import Navbar2 from "./navbar2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function submitHandle() {
    if (email == "") {
      toast.dark("Email is empty", {
        icon: "📧",
      });

      return false;
    }

    if (password == "") {
      toast.dark("Password is empty", {
        icon: "🔑",
      });

      return false;
    }

    const data = new FormData();
    data.append("email", email);
    data.append("password", password);

    fetch("https://server.itphonestore.com/itphone/login", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.message) {
          toast.dark("Mobile Number or Password Wrong", {
            icon: "⚠️",
          });
          return false;
        } else {
          localStorage.setItem("token", res.token);
          history.push("/account");
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div class="container-fluid my-5 ">
        <div class="row px-0 mx-0">
          <center class="my-lg-5 my-3">
            <Link to={"/"}>
              <img
                src={require("../image/icons/logo.png")}
                class="navbar-logo-2 mb-3"
                alt=""
                srcset=""
              />
            </Link>

            <div class="col-lg-4 col-12">
              <div class="card pt-2 rounded-0">
                <div class="card-body px-lg-5 px-3">
                  <p class="fs-5 m-0">Welcome</p>
                  <small class="text-muted">Login to continue</small>

                  <div class="col-lg-12 my-2">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      class="form-control log-f-border"
                      placeholder="example@mail.com"
                      aria-label="Your email"
                    />
                  </div>
                  <div class="col-lg-12">
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      class="form-control log-f-border"
                      placeholder="Password"
                      aria-label="Password"
                    />
                  </div>
                  <div class="text-end my-3">
                    <a href="#" class="text-warning f-c-acc">
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    onClick={submitHandle}
                    class="btn text-center w-100 fw-bold log-btn"
                  >
                    Log In
                  </button>
                  <div class="text-center my-3">
                    <Link to={"/reg/"} class="text-dark f-c-acc">
                      Register New Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    </>
  );
}

export default withRouter(Login);

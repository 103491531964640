import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

function Bottomnav() {
  // let history = useNavigate();
  const counter = useSelector((state) => state.counter);

  const [show, setShow] = useState(false);
  // const isAndroidWebView = window.hasOwnProperty("Android");
  // const [coun, setCoun] = useState(0);

  var url = window.location.href;
  url = url.replace("https://192.168.0.102:3000/", "");

  // useEffect(() => {
  //   window.visualViewport.addEventListener("resize", (event) => {
  //     var key = document.getElementById("botnav");

  //     if (isAndroidWebView) {
  //       if (key.style.display === "block") {
  //         key.style.display = "none";
  //         setCoun(coun + 1);
  //       }

  //       // if(key.style.display === "none"){
  //       //   key.style.display = "block";
  //       //   return false;
  //       // }
  //     }
  //   });
  // }, [coun]);

  // window.addEventListener("click", (event) => {
  //   var key = document.getElementById("botnav");

  //   if (isAndroidWebView) {
  //     key.style.display = "block";
  //   }
  // });

  // window.addEventListener("unfocus", (event) => {
  //   var key = document.getElementById("botnav");

  //   if (isAndroidWebView) {
  //     key.style.display = "block";
  //   }
  // });

  return (
    <nav class="navbar navbar-bottom fixed-bottom justify-content-between pt-0 mt-0">
      {/* <div class="row justify-content-between align-items-center">
        <div
          class={
            url == "/"
              ? "col-3 text-center pt-2 m-0"
              : "col-3 text-center pt-2 m-0"
          }
        >
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img
              src={
                url == ""
                  ? require("../image/icons/homea.png")
                  : require("../image/icons/home.png")
              }
              class="navbicon"
            />

            <p class="m-0 p-0" style={{ fontSize: 10, color: "#565656" }}>
              Home
            </p>
          </Link>
        </div>

        <div
          class={
            url == "categorym"
              ? "col-3 text-center pt-2 m-0"
              : "col-3 text-center pt-2 m-0"
          }
        >
          <Link to={"/categorym"} style={{ textDecoration: "none" }}>
            <img
              src={
                url == "categorym"
                  ? require("../image/icons/catea.png")
                  : require("../image/icons/cate.png")
              }
              class="navbicon"
            />

            <p class="m-0 p-0" style={{ fontSize: 10, color: "#565656" }}>
              Category
            </p>
          </Link>
        </div>

        <div
          class={
            url == "cart"
              ? "col-3 text-center pt-2 m-0"
              : "col-3 text-center pt-2 m-0"
          }
        >
          <Link to={"/cart"} style={{ textDecoration: "none" }}>
            <img
              src={
                url == "cart"
                  ? require("../image/icons/carta.png")
                  : require("../image/icons/cart.png")
              }
              class="navbicon"
            />

            <p class="m-0 p-0" style={{ fontSize: 10, color: "#565656" }}>
              Bag
            </p>
          </Link>
        </div>

        <div
          class={
            url == "account"
              ? "col-3 text-center pt-2 m-0"
              : "col-3 text-center pt-2 m-0"
          }
        >
          <Link to={localStorage.getItem("token") == null ? "/login" : "/account"} style={{ textDecoration: "none" }}>
            <img
              src={
                url == "account"
                  ? require("../image/icons/acca.png")
                  : require("../image/icons/acc.png")
              }
              class="navbicon"
            />

            <p class="m-0 p-0" style={{ fontSize: 10, color: "#565656" }}>
              Account
            </p>
          </Link>
        </div>
      </div> */}
      <Link
        to={"/cart"}
        class="d-flex flex-row px-4 py-2 align-items-center justify-content-center"
        style={{ textDecoration: "None" }}
      >
        <p class="bottomcart mb-0 px-4">Your Shopping Cart</p>

        <div
          type="button"
          class="btn btn-white text-white position-relative fa-solid fa-shopping-cart mx-2 fs-4"
        >
          <span class="position-absolute top-100 start-100 translate-middle badge badge-cart bg-warning py-1">
            <p style={{ fontSize: 10 }}>{counter}</p>
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>
      </Link>
    </nav>
  );
}
export default Bottomnav;
